export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const FETCH_PACKAGE = "FETCH_PACKAGE";
export const PATCH_PACKAGE = "PATCH_PACKAGE";
export const SYNC_PACKAGE = "SYNC_PACKAGE";
export const ACTIVATE_PACKAGE = "ACTIVATE_PACKAGE";

export const SET_PAGE = "SET_PAGE";
export const SET_SORT = "SET_SORT";
export const SET_FILTER = "SET_FILTER";

export const FETCH_META = "FETCH_META";
export const FETCH_SOURCE_META = "FETCH_SOURCE_META";
