import Vue from "vue";
//Import mutation actions constants
import { FETCH_ACCOUNTS, SET_FILTER } from "@/store/actions/donor";

export const mutations = {
  //Store current page
  [FETCH_ACCOUNTS]: (state, data) => {
    Vue.set(state, "items", data.batch);
    Vue.set(state.pagination, "totalItems", data.total);
    // state.pagination.totalItems = data.total;
  },
  //Store current page
  [SET_FILTER]: (state, filters) => {
    state.pagination.page = 1;
    state.filters = filters;
  },
};
