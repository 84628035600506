<template>
  <div>
    {{ $t("overlay.sign_in_message") }}
  </div>
</template>

<script>
export default {
  name: "Overlay",
};
</script>
