export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_RECOVERY_PASSWORD = "AUTH_RECOVERY_PASSWORD";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_VALIDATE_RECOVERY_CODE = "AUTH_VALIDATE_RECOVERY_CODE";

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_REFRESH = "USER_REFRESH";
