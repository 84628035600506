import { state } from "@/store/modules/account/generation-details/state";
import { getters } from "@/store/modules/account/generation-details/getters";
import { actions } from "@/store/modules/account/generation-details/actions";
import { mutations } from "@/store/modules/account/generation-details/mutations";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
