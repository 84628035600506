import _ from "lodash";

//Import store action constants
import {
  FETCH_TAGS,
  FETCH_TAG_CATEGORIES,
  FETCH_TAGS_PARTIAL,
  PATCH_TAG,
  DELETE_TAG,
  MARK_ACCOUNTS,
} from "@/store/actions/tag";

//Import API endpoint constants
import {
  API_FETCH_TAGS,
  API_PATCH_TAG,
  API_PUT_TAG,
  API_DELETE_TAG,
  API_MARK_ACCOUNTS,
} from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch packages list
  [FETCH_TAG_CATEGORIES]: ({ commit, getters }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_TAGS,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
          sort: getters.formattedSort,
          search: _.merge({}, getters.formattedFilters, { level: 0 }),
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_TAG_CATEGORIES, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch packages list
  [FETCH_TAGS]: ({ commit, getters }, { parentId }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_TAGS,
        params: {
          limit: -1,
          offset: 0,
          sort: getters.formattedSort,
          search: _.merge({}, getters.formattedFilters, { level: 1, parentId }),
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_TAGS, _.merge({}, payload.data, { parentId }));
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch packages list
  [FETCH_TAGS_PARTIAL]: (context, { limit, offset, search }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_TAGS,
        params: { limit, offset, search },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_TAG]: (context, { tag, id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: id ? API_PATCH_TAG : API_PUT_TAG,
        data: tag,
        parts: {
          id: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_TAG]: (context, { id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_DELETE_TAG,
        parts: {
          id: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MARK_ACCOUNTS]: (context, { data }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_MARK_ACCOUNTS,
        data,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
