import _ from "lodash";

import {
  SCOPE_FACEBOOK,
  SCOPE_INSTAGRAM,
  SCOPE_LINKEDIN,
  SCOPE_TWITTER,
  SCOPE_VKONTAKTE,
} from "../service/getters";
import { countries } from "../../../config/country.config";

const PARAMS_AVATAR_CHANGING = "avatarChanging";
const PARAMS_AVATAR_CHANGING_DAY_LIMIT = "avatarChangingDayLimit";

const PARAMS_CHECK_IN = "checkIn";
const PARAMS_CHECK_IN_DAY_LIMIT = "checkInDayLimit";

// old, will be removed after complete refactoring o social side
const PARAMS_CLEANING_OUTGOING_REQUESTS = "cleaningOutgoingRequests";
const PARAMS_CLEANING_OUTGOING_REQUESTS_COUNT = "cleaningOutgoingRequestsCount";
const PARAMS_CLEANING_OUTGOING_REQUESTS_DAY_LIMIT =
  "cleaningOutgoingRequestsDayLimit";
const PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING =
  "outgoingFriendRequestsCleaning";
const PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING_LIMIT =
  "outgoingFriendRequestsCleaningLimit";
const PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING_DAY_LIMIT =
  "outgoingFriendRequestsCleaningDayLimit";

// old, will be removed after complete refactoring o social side
const PARAMS_CONFIRMING_REQUESTS = "confirmingRequests";
const PARAMS_CONFIRMING_REQUESTS_COUNT = "confirmingRequestsCount";
const PARAMS_CONFIRMING_REQUESTS_LIMIT = "confirmingRequestsDayLimit";

const PARAMS_INCOMING_FRIEND_REQUESTS = "incomingFriendRequests";
const PARAMS_INCOMING_FRIEND_REQUESTS_LIMIT = "incomingFriendRequestsLimit";
const PARAMS_INCOMING_FRIEND_REQUESTS_DAY_LIMIT =
  "incomingFriendRequestsDayLimit";
const PARAMS_INCOMING_FRIEND_REQUESTS_MODE = "incomingFriendRequestsMode";
const PARAMS_INCOMING_FRIEND_REQUESTS_FILTER = "incomingFriendRequestsFilter";

const PARAMS_FEED_COMMENTING = "feedCommenting";
const PARAMS_FEED_COMMENTING_COUNT_LIMIT = "feedCommentingCountLimit";
const PARAMS_FEED_COMMENTING_DAY_LIMIT = "feedCommentingDayLimit";

// old, will be removed after complete refactoring o social side
const PARAMS_FEED_LIKING = "feedliking";
const PARAMS_FEED_LIKING_COUNT_LIKE_LIMIT = "feedlikingCountLikeLimit";
const PARAMS_FEED_LIKING_DAY_LIMIT = "feedlikingDayLimit";
const PARAMS_FEED_LIKING_PAUSE_LIMIT = "feedlikingPauseLimit";
const PARAMS_FEED_LIKING_SCROLL_DOWN_LIMIT = "feedlikingScrollDownLimit";

const PARAMS_FEED_REACTING = "feedReacting";
const PARAMS_FEED_REACTING_DAY_LIMIT = "feedReactingDayLimit";
const PARAMS_FEED_REACTING_REACTIONS_LIMIT = "feedReactingReactionsLimit";
const PARAMS_FEED_REACTING_PAUSE_LIMIT = "feedReactingPauseLimit";

const PARAMS_FRIENDING = "friending";
const PARAMS_FRIENDING_DAY_LIMIT = "friendingDayLimit";
const PARAMS_FRIENDING_LIMIT = "friendingLimit";
const PARAMS_FRIENDING_COUNT = "friendingCount";

// old, will be removed after complete refactoring o social side
const PARAMS_NOTIFICATIONS = "notifications";
const PARAMS_NOTIFICATIONS_DAY_LIMIT = "notificationsDayLimit";

const PARAMS_NOTIFICATIONS_REACTING = "notificationsReacting";
const PARAMS_NOTIFICATIONS_REACTING_DAY_LIMIT = "notificationsReactingDayLimit";

const PARAMS_POSTING = "posting";
const PARAMS_POSTING_DAY_LIMIT = "postingDayLimit";
const PARAMS_POSTING_OPTIONS = "postingOptions";
const PARAMS_POSTING_COUNT = "postingCount";

const PARAMS_USER_PHOTO_POSTING = "userPhotoPosting";
const PARAMS_USER_PHOTO_POSTING_DAY_LIMIT = "userPhotoPostingDayLimit";
const PARAMS_USER_PHOTO_POSTING_FEELINGS = "userPhotoPostingFeelings";
const PARAMS_USER_PHOTO_POSTING_FEELINGS_CHANCE =
  "userPhotoPostingFeelingsChance";

const PARAMS_TARGET_FRIENDING = "targetFriending";
const PARAMS_TARGET_FRIENDING_DAY_LIMIT = "targetFriendingDayLimit";
const PARAMS_TARGET_FRIENDING_LIMIT = "targetFriendingLimit";
const PARAMS_TARGET_FRIENDING_OPTIONS = "targetFriendingOptions";

const PARAMS_TARGET_REPOSTING = "targetReposting";
const PARAMS_TARGET_REPOSTING_DAY_LIMIT = "targetRepostingDayLimit";
const PARAMS_TARGET_REPOSTING_REPOST_COUNT = "targetRepostingRepostCount";

const PARAMS_TARGET_SUBSCRIPTION = "targetSubscription";
const PARAMS_TARGET_SUBSCRIPTION_DAY_LIMIT = "targetSubscriptionDayLimit";

const PARAMS_SEARCHING = "searching";
const PARAMS_SEARCHING_DAY_LIMIT = "searchingDayLimit";

const PARAMS_TARGET_COMMENTING = "targetCommenting";
const PARAMS_TARGET_COMMENTING_DAY_LIMIT = "targetCommentingDayLimit";

const PARAMS_REPOSTING = "reposting";
const PARAMS_REPOSTING_COUNT = "repostingCount";
const PARAMS_REPOSTING_LIMIT = "repostingLimit";
const PARAMS_REPOSTING_DAY_LIMIT = "repostingDayLimit";

const PARAMS_COMMENTING = "commenting";
const PARAMS_COMMENTING_DAY_LIMIT = "commentingDayLimit";
const PARAMS_COMMENTING_LIMIT = "commentingLimit";

const PARAMS_MESSAGING = "messaging";
const PARAMS_MESSAGING_DAY_LIMIT = "messagingDayLimit";

const PARAMS_OWN_PAGE_CHECK = "ownPageCheck";
const PARAMS_OWN_PAGE_CHECK_DAY_LIMIT = "ownPageCheckDayLimit";

const PARAMS_STORY_POSTING = "storyPosting";
const PARAMS_STORY_POSTING_DAY_LIMIT = "storyPostingDayLimit";

const PARAMS_STORY_WATCHING = "storyWatching";
const PARAMS_STORY_WATCHING_DAY_LIMIT = "storyWatchingDayLimit";

const PARAMS_SUGGEST_SCROLLING = "suggestScrolling";
const PARAMS_SUGGEST_SCROLLING_DAY_LIMIT = "suggestScrollingDayLimit";

const PARAMS_GENDER_API = "genderApi";
const PARAMS_GENDER_API_COUNTRIES_WHITELIST = "genderApiCountriesWhitelist";

const PARAMS_FEED_ADVERTISING = "feedAdvertising";
const PARAMS_FEED_ADVERTISING_DAY_LIMIT = "feedAdvertisingDayLimit";

const PARAMS_FRIENDS_CLEARING = "friendsClearing";
const PARAMS_FRIENDS_CLEARING_DAY_LIMIT = "friendsClearingDayLimit";

const PARAMS_SUBSCRIPTION = "subscription";
const PARAMS_SUBSCRIPTION_DAY_LIMIT = "subscriptionDayLimit";
const PARAMS_SUBSCRIPTION_LIMIT = "subscriptionLimit";

export const TYPE_SWITCHER = "switcher";
export const TYPE_RANGE = "range";
export const TYPE_SELECT = "select";
export const TYPE_NUMBER = "number";
export const TYPE_STRING = "string";
export const TYPE_SCROLL_DOWN_LIMIT = "scrollDownLimit";
export const TYPE_FRIENDING_LIMIT = "friendingLimit";
export const TYPE_POSTING_DAY_LIMIT = "postingDayLimit";
export const TYPE_POSTING_OPTIONS = "postingOptions";
export const TYPE_MULTISELECT = "multiselect";

export const VALUE_DECLINE = "Decline";
export const VALUE_CONFIRM = "Confirm";

export const PARAMS_MAPPING = {
  [SCOPE_FACEBOOK]: {
    [PARAMS_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FRIENDING_LIMIT]: { type: TYPE_FRIENDING_LIMIT },
      },
    },
    [PARAMS_TARGET_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_TARGET_FRIENDING_LIMIT]: { type: TYPE_FRIENDING_LIMIT },
      },
    },
    [PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING_DAY_LIMIT]: {
          type: TYPE_NUMBER,
        },
      },
    },
    [PARAMS_INCOMING_FRIEND_REQUESTS]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_INCOMING_FRIEND_REQUESTS_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_INCOMING_FRIEND_REQUESTS_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_INCOMING_FRIEND_REQUESTS_MODE]: { type: TYPE_SELECT },
      },
    },
    [PARAMS_GENDER_API]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_GENDER_API_COUNTRIES_WHITELIST]: { type: TYPE_MULTISELECT },
      },
    },
    [PARAMS_AVATAR_CHANGING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_AVATAR_CHANGING_DAY_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_CHECK_IN]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_CHECK_IN_DAY_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_FEED_COMMENTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FEED_COMMENTING_COUNT_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_FEED_COMMENTING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_FEED_REACTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FEED_REACTING_REACTIONS_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_FEED_REACTING_DAY_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_FEED_REACTING_PAUSE_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_NOTIFICATIONS_REACTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_NOTIFICATIONS_REACTING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_POSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_POSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_POSTING_OPTIONS]: {
          type: TYPE_POSTING_OPTIONS,
          options: [
            "image",
            "text",
            "text_image",
            "text_url",
            "text_geo",
            "image_text_geo",
          ],
        },
      },
    },
    [PARAMS_USER_PHOTO_POSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_USER_PHOTO_POSTING_DAY_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_USER_PHOTO_POSTING_FEELINGS]: { type: TYPE_SWITCHER },
        [PARAMS_USER_PHOTO_POSTING_FEELINGS_CHANCE]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_TARGET_REPOSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_REPOSTING_DAY_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_TARGET_SUBSCRIPTION]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_SUBSCRIPTION_DAY_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_FEED_ADVERTISING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FEED_ADVERTISING_DAY_LIMIT]: { type: TYPE_RANGE },
      },
    },
  },
  [SCOPE_TWITTER]: {
    [PARAMS_CLEANING_OUTGOING_REQUESTS]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_CLEANING_OUTGOING_REQUESTS_COUNT]: { type: TYPE_RANGE },
        [PARAMS_CLEANING_OUTGOING_REQUESTS_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_FEED_LIKING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FEED_LIKING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FEED_LIKING_SCROLL_DOWN_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FRIENDING_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_NOTIFICATIONS]: {
      type: TYPE_SWITCHER,
    },
    [PARAMS_POSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_POSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_POSTING_OPTIONS]: {
          type: TYPE_POSTING_OPTIONS,
          options: ["image", "text", "text_image", "text_url"],
        },
      },
    },
    [PARAMS_TARGET_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_TARGET_FRIENDING_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_TARGET_FRIENDING_OPTIONS]: {
          type: TYPE_POSTING_OPTIONS,
          options: ["whitelist", "common"],
        },
      },
    },
    [PARAMS_SEARCHING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_SEARCHING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_TARGET_COMMENTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_COMMENTING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_TARGET_REPOSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_REPOSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_TARGET_REPOSTING_REPOST_COUNT]: { type: TYPE_RANGE },
      },
    },
  },
  [SCOPE_LINKEDIN]: {
    [PARAMS_CONFIRMING_REQUESTS]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_CONFIRMING_REQUESTS_COUNT]: { type: TYPE_RANGE },
        [PARAMS_CONFIRMING_REQUESTS_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_FEED_LIKING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FEED_LIKING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FEED_LIKING_COUNT_LIKE_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FRIENDING_COUNT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_POSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_POSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_POSTING_COUNT]: { type: TYPE_NUMBER },
        [PARAMS_POSTING_OPTIONS]: {
          type: TYPE_POSTING_OPTIONS,
          options: [
            "text_image_link",
            "text_image",
            "text_link",
            "link_image",
            "text",
            "image",
          ],
        },
      },
    },
    [PARAMS_REPOSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_REPOSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_REPOSTING_COUNT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_TARGET_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_TARGET_FRIENDING_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_TARGET_REPOSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_REPOSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_TARGET_REPOSTING_REPOST_COUNT]: { type: TYPE_NUMBER },
      },
    },
  },
  [SCOPE_INSTAGRAM]: {
    [PARAMS_CLEANING_OUTGOING_REQUESTS]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_CLEANING_OUTGOING_REQUESTS_COUNT]: { type: TYPE_RANGE },
        [PARAMS_CLEANING_OUTGOING_REQUESTS_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_COMMENTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_COMMENTING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_FEED_LIKING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FEED_LIKING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FEED_LIKING_PAUSE_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_FEED_LIKING_SCROLL_DOWN_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FRIENDING_LIMIT]: { type: TYPE_FRIENDING_LIMIT },
      },
    },
    [PARAMS_MESSAGING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_MESSAGING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_OWN_PAGE_CHECK]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_OWN_PAGE_CHECK_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_NOTIFICATIONS]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_NOTIFICATIONS_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_POSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_POSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_POSTING_OPTIONS]: {
          type: TYPE_POSTING_OPTIONS,
          options: ["image", "text_image", "image_text_geo"],
        },
      },
    },
    [PARAMS_SEARCHING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_SEARCHING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_STORY_POSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_STORY_POSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_STORY_WATCHING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_STORY_WATCHING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_SUGGEST_SCROLLING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_SUGGEST_SCROLLING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_TARGET_COMMENTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_COMMENTING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_TARGET_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_TARGET_FRIENDING_LIMIT]: { type: TYPE_NUMBER },
      },
    },
  },
  [SCOPE_VKONTAKTE]: {
    [PARAMS_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_FRIENDING_LIMIT]: { type: TYPE_FRIENDING_LIMIT },
      },
    },
    [PARAMS_TARGET_FRIENDING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_FRIENDING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_TARGET_FRIENDING_LIMIT]: { type: TYPE_FRIENDING_LIMIT },
      },
    },
    [PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_OUTGOING_FRIEND_REQUESTS_CLEANING_DAY_LIMIT]: {
          type: TYPE_NUMBER,
        },
      },
    },
    [PARAMS_INCOMING_FRIEND_REQUESTS]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_INCOMING_FRIEND_REQUESTS_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_INCOMING_FRIEND_REQUESTS_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_INCOMING_FRIEND_REQUESTS_MODE]: { type: TYPE_SELECT },
        [PARAMS_INCOMING_FRIEND_REQUESTS_FILTER]: { type: TYPE_SELECT },
      },
    },
    [PARAMS_FRIENDS_CLEARING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FRIENDS_CLEARING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_FEED_REACTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_FEED_REACTING_REACTIONS_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_FEED_REACTING_DAY_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_FEED_REACTING_PAUSE_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_POSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_POSTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_POSTING_OPTIONS]: {
          type: TYPE_POSTING_OPTIONS,
          options: [
            "image",
            "text",
            "text_image",
            "text_url",
            "text_geo",
            "image_text_geo",
          ],
        },
      },
    },
    [PARAMS_REPOSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_REPOSTING_DAY_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_REPOSTING_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_TARGET_REPOSTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_TARGET_REPOSTING_DAY_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_SUBSCRIPTION]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_SUBSCRIPTION_DAY_LIMIT]: { type: TYPE_RANGE },
        [PARAMS_SUBSCRIPTION_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_NOTIFICATIONS_REACTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_NOTIFICATIONS_REACTING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
    [PARAMS_COMMENTING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_COMMENTING_DAY_LIMIT]: { type: TYPE_NUMBER },
        [PARAMS_COMMENTING_LIMIT]: { type: TYPE_RANGE },
      },
    },
    [PARAMS_MESSAGING]: {
      type: TYPE_SWITCHER,
      children: {
        [PARAMS_MESSAGING_DAY_LIMIT]: { type: TYPE_NUMBER },
      },
    },
  },
};

export const VALUE_MAPPING = {
  [PARAMS_INCOMING_FRIEND_REQUESTS_MODE]: [VALUE_DECLINE, VALUE_CONFIRM],
  [PARAMS_INCOMING_FRIEND_REQUESTS_FILTER]: ["", "city", "region"],
  [PARAMS_GENDER_API_COUNTRIES_WHITELIST]: countries,
};

export const getters = {
  //Base getters
  items: (state) => state.items,
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,
  sort: (state) => state.sort,

  //Formatters
  formattedSort: (state) =>
    state.sort.sortBy ? (state.sort.desc ? "-" : "") + state.sort.sortBy : null,
  formattedFilters: (state) => {
    let filters = {};
    _.forIn(state.filters, function (value, key) {
      if (value !== null && (!(value instanceof Array) || value.length)) {
        filters[key] = value;
      }
    });
    return filters;
  },

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,
  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),

  availableSegments: () => ["type", "stage"],
  availableScopes: () => [
    SCOPE_FACEBOOK,
    SCOPE_TWITTER,
    SCOPE_LINKEDIN,
    SCOPE_INSTAGRAM,
    SCOPE_VKONTAKTE,
  ],
};
