//Import store action constants
import {
  DELETE_BLACKLIST_ITEMS,
  FETCH_BLACKLIST_ITEMS,
  PATCH_BLACKLIST_ITEMS,
  PUT_BLACKLIST_ITEMS,
  // DELETE_BLACKLIST_ITEMS,
} from "@/store/actions/recommender";

//Import API endpoint constants
import {
  API_DELETE_BLACKLIST_ITEMS,
  API_FETCH_BLACKLIST_ITEMS,
  API_PATCH_BLACKLIST_ITEMS,
  // API_PATCH_BLACKLIST_ITEMS,
  API_PUT_BLACKLIST_ITEMS,
  // API_DELETE_BLACKLIST_ITEMS,
} from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch packages list
  [FETCH_BLACKLIST_ITEMS]: ({ commit, getters }, { listId }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_BLACKLIST_ITEMS,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
          sort: getters.formattedSort,
          search: getters.formattedFilters,
        },
        parts: { id: listId },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_BLACKLIST_ITEMS, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_BLACKLIST_ITEMS]: (context, { ids, listId }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PUT_BLACKLIST_ITEMS,
        data: {
          items: ids,
        },
        parts: {
          id: listId,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_BLACKLIST_ITEMS]: (context, { ids, listId, enabled }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PATCH_BLACKLIST_ITEMS,
        data: {
          enabled,
          itemIds: ids,
        },
        parts: {
          id: listId,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_BLACKLIST_ITEMS]: (context, { ids, listId }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_DELETE_BLACKLIST_ITEMS,
        data: {
          items: ids,
        },
        parts: {
          id: listId,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
