export const ADD_CONTENT_RECOMMENDER_FILTER = "ADD_CONTENT_RECOMMENDER_FILTER";
export const REMOVE_CONTENT_RECOMMENDER_FILTER =
  "REMOVE_CONTENT_RECOMMENDER_FILTER";
export const SET_DEFAULT_CONTENT_RECOMMENDER_FILTER =
  "SET_DEFAULT_CONTENT_RECOMMENDER_FILTER";
export const SET_CONTENT_RECOMMENDER_FILTER = "SET_CONTENT_RECOMMENDER_FILTER";

export const ADD_FRIENDS_RECOMMENDER_FILTER = "ADD_FRIENDS_RECOMMENDER_FILTER";
export const REMOVE_FRIENDS_RECOMMENDER_FILTER =
  "REMOVE_FRIENDS_RECOMMENDER_FILTER";
export const SET_DEFAULT_FRIENDS_RECOMMENDER_FILTER =
  "SET_DEFAULT_FRIENDS_RECOMMENDER_FILTER";
export const SET_FRIENDS_RECOMMENDER_FILTER = "SET_FRIENDS_RECOMMENDER_FILTER";

export const ADD_AUDIENCE_RECOMMENDER_FILTER =
  "ADD_AUDIENCE_RECOMMENDER_FILTER";
export const REMOVE_AUDIENCE_RECOMMENDER_FILTER =
  "REMOVE_AUDIENCE_RECOMMENDER_FILTER";
export const SET_DEFAULT_AUDIENCE_RECOMMENDER_FILTER =
  "SET_DEFAULT_AUDIENCE_RECOMMENDER_FILTER";
export const SET_AUDIENCE_RECOMMENDER_FILTER =
  "SET_AUDIENCE_RECOMMENDER_FILTER";

export const ADD_ABUSE_RECOMMENDER_FILTER = "ADD_ABUSE_RECOMMENDER_FILTER";
export const REMOVE_ABUSE_RECOMMENDER_FILTER =
  "REMOVE_ABUSE_RECOMMENDER_FILTER";
export const SET_DEFAULT_ABUSE_RECOMMENDER_FILTER =
  "SET_DEFAULT_ABUSE_RECOMMENDER_FILTER";
export const SET_ABUSE_RECOMMENDER_FILTER = "SET_ABUSE_RECOMMENDER_FILTER";

export const ADD_VECTOR_FILTER = "ADD_VECTOR_FILTER";
export const REMOVE_VECTOR_FILTER = "REMOVE_VECTOR_FILTER";
export const SET_DEFAULT_VECTOR_FILTER = "SET_DEFAULT_VECTOR_FILTER";
export const SET_VECTOR_FILTER = "SET_VECTOR_FILTER";

export const ADD_ACCOUNT_FILTER = "ADD_ACCOUNT_FILTER";
export const REMOVE_ACCOUNT_FILTER = "REMOVE_ACCOUNT_FILTER";
export const SET_DEFAULT_ACCOUNT_FILTER = "SET_DEFAULT_ACCOUNT_FILTER";
export const SET_ACCOUNT_FILTER = "SET_ACCOUNT_FILTER";

export const ADD_FACEBOOK_EMULATION_SETTINGS_FILTER =
  "ADD_FACEBOOK_EMULATION_SETTINGS_FILTER";
export const REMOVE_FACEBOOK_EMULATION_SETTINGS_FILTER =
  "REMOVE_FACEBOOK_EMULATION_SETTINGS_FILTER";
export const SET_DEFAULT_FACEBOOK_EMULATION_SETTINGS_FILTER =
  "SET_DEFAULT_FACEBOOK_EMULATION_SETTINGS_FILTER";
export const SET_FACEBOOK_EMULATION_SETTINGS_FILTER =
  "SET_FACEBOOK_EMULATION_SETTINGS_FILTER";

export const ADD_ACCOUNT_HISTORIES_FILTER = "ADD_ACCOUNT_HISTORIES_FILTER";
export const REMOVE_ACCOUNT_HISTORIES_FILTER =
  "REMOVE_ACCOUNT_HISTORIES_FILTER";
export const SET_DEFAULT_ACCOUNT_HISTORIES_FILTER =
  "SET_DEFAULT_ACCOUNT_HISTORIES_FILTER";
export const SET_ACCOUNT_HISTORIES_FILTER = "SET_ACCOUNT_HISTORIES_FILTER";
