import { SOCKET_EXPORT } from "@/store/actions/socket";
import downloadFile from "@/utils/file";

export const actions = {
  //Fetch packages list
  [SOCKET_EXPORT]: (context, data) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      const { payload } = data;
      if (payload && payload.status === "completed") {
        downloadFile(payload).catch((err) => {
          reject(err);
        });
      }
    });
  },
};
