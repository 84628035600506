import _ from "lodash";

export const emptyFilters = {
  //Filters state
  id: null,
  title: null,
  description: null,
  price: null,
  proxiesAmount: null,
  source: null,
  status: null,
  expiredAt: null,
};

const defaultFilters = _.merge({}, emptyFilters, {
  status: "active",
});

export const state = {
  items: [], //List of packages with base info only
  itemDetails: {}, //List of packages with it's details, such as login & password
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 12,
    totalItems: 0,
  },
  sort: {
    //Ordering state, attribute & direction
    sortBy: "expiredAt",
    desc: false,
  },
  filters: defaultFilters,
};
