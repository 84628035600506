import _ from "lodash";
import {
  CALCULATE_ACCOUNT_GENERATION,
  FETCH_ACCOUNT_GENERATIONS,
  RESET_GENERATION_PARAMS,
  SET_GENERATION_PARAMS,
} from "@/store/actions/account-generation";
import { emptyGenerationParams } from "@/store/modules/account/generation/state";

export const mutations = {
  [SET_GENERATION_PARAMS]: (state, params) => {
    state.params = params;
  },
  [FETCH_ACCOUNT_GENERATIONS]: (state, data) => {
    state.items = data.batch;
    state.pagination.totalItems = data.total;
  },
  [CALCULATE_ACCOUNT_GENERATION]: (state, data) => {
    state.generation = data.batch;
  },
  [RESET_GENERATION_PARAMS]: (state) => {
    state.params = _.merge({}, emptyGenerationParams, {});
    state.generation = [];
  },
};
