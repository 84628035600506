import {
  ADD_CONTENT_RECOMMENDER_FILTER,
  ADD_FACEBOOK_EMULATION_SETTINGS_FILTER,
  ADD_AUDIENCE_RECOMMENDER_FILTER,
  ADD_ABUSE_RECOMMENDER_FILTER,
  ADD_VECTOR_FILTER,
  ADD_ACCOUNT_FILTER,
  REMOVE_CONTENT_RECOMMENDER_FILTER,
  REMOVE_FACEBOOK_EMULATION_SETTINGS_FILTER,
  REMOVE_AUDIENCE_RECOMMENDER_FILTER,
  REMOVE_ABUSE_RECOMMENDER_FILTER,
  REMOVE_VECTOR_FILTER,
  REMOVE_ACCOUNT_FILTER,
  SET_CONTENT_RECOMMENDER_FILTER,
  SET_FACEBOOK_EMULATION_SETTINGS_FILTER,
  SET_AUDIENCE_RECOMMENDER_FILTER,
  SET_ABUSE_RECOMMENDER_FILTER,
  SET_VECTOR_FILTER,
  SET_ACCOUNT_FILTER,
  SET_DEFAULT_CONTENT_RECOMMENDER_FILTER,
  SET_DEFAULT_FACEBOOK_EMULATION_SETTINGS_FILTER,
  SET_DEFAULT_AUDIENCE_RECOMMENDER_FILTER,
  SET_DEFAULT_ABUSE_RECOMMENDER_FILTER,
  SET_DEFAULT_VECTOR_FILTER,
  SET_DEFAULT_ACCOUNT_FILTER,
  ADD_ACCOUNT_HISTORIES_FILTER,
  REMOVE_ACCOUNT_HISTORIES_FILTER,
  SET_DEFAULT_ACCOUNT_HISTORIES_FILTER,
  SET_ACCOUNT_HISTORIES_FILTER,
} from "../../actions/persistentStorage";

export const actions = {
  [ADD_FACEBOOK_EMULATION_SETTINGS_FILTER]: ({ commit }, data) => {
    commit(ADD_FACEBOOK_EMULATION_SETTINGS_FILTER, data);
  },
  [REMOVE_FACEBOOK_EMULATION_SETTINGS_FILTER]: ({ commit }, data) => {
    commit(REMOVE_FACEBOOK_EMULATION_SETTINGS_FILTER, data);
  },
  [SET_DEFAULT_FACEBOOK_EMULATION_SETTINGS_FILTER]: ({ commit }, data) => {
    commit(SET_DEFAULT_FACEBOOK_EMULATION_SETTINGS_FILTER, data);
  },
  [SET_FACEBOOK_EMULATION_SETTINGS_FILTER]: ({ commit }, data) => {
    commit(SET_FACEBOOK_EMULATION_SETTINGS_FILTER, data);
  },
  [ADD_CONTENT_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(ADD_CONTENT_RECOMMENDER_FILTER, data);
  },
  [REMOVE_CONTENT_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(REMOVE_CONTENT_RECOMMENDER_FILTER, data);
  },
  [SET_DEFAULT_CONTENT_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(SET_DEFAULT_CONTENT_RECOMMENDER_FILTER, data);
  },
  [SET_CONTENT_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(SET_CONTENT_RECOMMENDER_FILTER, data);
  },
  [ADD_AUDIENCE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(ADD_AUDIENCE_RECOMMENDER_FILTER, data);
  },
  [REMOVE_AUDIENCE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(REMOVE_AUDIENCE_RECOMMENDER_FILTER, data);
  },
  [SET_DEFAULT_AUDIENCE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(SET_DEFAULT_AUDIENCE_RECOMMENDER_FILTER, data);
  },
  [SET_AUDIENCE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(SET_AUDIENCE_RECOMMENDER_FILTER, data);
  },
  [ADD_ABUSE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(ADD_ABUSE_RECOMMENDER_FILTER, data);
  },
  [REMOVE_ABUSE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(REMOVE_ABUSE_RECOMMENDER_FILTER, data);
  },
  [SET_DEFAULT_ABUSE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(SET_DEFAULT_ABUSE_RECOMMENDER_FILTER, data);
  },
  [SET_ABUSE_RECOMMENDER_FILTER]: ({ commit }, data) => {
    commit(SET_ABUSE_RECOMMENDER_FILTER, data);
  },
  [ADD_VECTOR_FILTER]: ({ commit }, data) => {
    commit(ADD_VECTOR_FILTER, data);
  },
  [REMOVE_VECTOR_FILTER]: ({ commit }, data) => {
    commit(REMOVE_VECTOR_FILTER, data);
  },
  [SET_DEFAULT_VECTOR_FILTER]: ({ commit }, data) => {
    commit(SET_DEFAULT_VECTOR_FILTER, data);
  },
  [SET_VECTOR_FILTER]: ({ commit }, data) => {
    commit(SET_VECTOR_FILTER, data);
  },
  [ADD_ACCOUNT_FILTER]: ({ commit }, data) => {
    commit(ADD_ACCOUNT_FILTER, data);
  },
  [REMOVE_ACCOUNT_FILTER]: ({ commit }, data) => {
    commit(REMOVE_ACCOUNT_FILTER, data);
  },
  [SET_DEFAULT_ACCOUNT_FILTER]: ({ commit }, data) => {
    commit(SET_DEFAULT_ACCOUNT_FILTER, data);
  },
  [SET_ACCOUNT_FILTER]: ({ commit }, data) => {
    commit(SET_ACCOUNT_FILTER, data);
  },
  [ADD_ACCOUNT_HISTORIES_FILTER]: ({ commit }, data) => {
    commit(ADD_ACCOUNT_HISTORIES_FILTER, data);
  },
  [REMOVE_ACCOUNT_HISTORIES_FILTER]: ({ commit }, data) => {
    commit(REMOVE_ACCOUNT_HISTORIES_FILTER, data);
  },
  [SET_DEFAULT_ACCOUNT_HISTORIES_FILTER]: ({ commit }, data) => {
    commit(SET_DEFAULT_ACCOUNT_HISTORIES_FILTER, data);
  },
  [SET_ACCOUNT_HISTORIES_FILTER]: ({ commit }, data) => {
    commit(SET_ACCOUNT_HISTORIES_FILTER, data);
  },
};
