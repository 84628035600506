export default function admin({ next, store }) {
  console.log(store.getters["isSuperAdmin"]);
  if (!store.getters["isAuthenticated"]) {
    return next({
      name: "Login",
    });
  }
  if (!store.getters["isSuperAdmin"]) {
    return next({
      name: "Dashboard",
    });
  }
  return next();
}
