import _ from "lodash";

const SETTINGS_ONLY_ME = "Only me";
const SETTINGS_FRIENDS = "Friends";
const SETTINGS_PUBLIC = "Public";
const SETTINGS_EVERYONE = "Everyone";
const SETTINGS_FRIENDS_OF_FRIENDS = "Friends of friends";
const SETTINGS_ON = "On";
const SETTINGS_OFF = "Off";

export const getters = {
  //Base getters
  items: (state) => state.items,
  itemsDetails: (state) => state.itemDetails,
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,
  sort: (state) => state.sort,

  //Formatters
  formattedSort: (state) =>
    state.sort.sortBy ? (state.sort.desc ? "-" : "") + state.sort.sortBy : null,
  formattedFilters: (state) => {
    let filters = {};
    _.forIn(state.filters, function (value, key) {
      if (value !== null && (!(value instanceof Array) || value.length)) {
        filters[key] = value;
      }
    });
    return filters;
  },

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,
  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),

  //Tag
  categoryColor: () => {
    return {
      media_public: "green",
      prof_type: "indigo",
      politic: "red",
      psycho_type: "blue",
      page_audience: "teal",
      category: "orange",
      event: "purple",
      users_friends: "lime",
      campaign: "blue-grey",
      abuse: "amber",
    };
  },
  getTagColorByCategory: (state, getter) => ({ category, defaultValue }) => {
    return _.get(getter.categoryColor, category, defaultValue);
  },

  //account
  availableGenders: () => {
    return [
      {
        gender: 1,
        key: "female",
      },
      {
        gender: 2,
        key: "male",
      },
    ];
  },
  getSymbolByGender: (state, getter) => ({ gender, defaultValue }) => {
    const genderObj = _.find(getter.availableGenders, {
      gender: parseInt(gender),
    });
    return genderObj ? genderObj.key : defaultValue;
  },
  //language
  availableLanguages: () => [1, 2, 4],
  languageAbr: () => {
    return {
      1: "gb",
      2: "ru",
      4: "ua",
    };
  },
  availableLanguageLabels: () => {
    return [
      {
        key: 1,
        label: "EN",
      },
      {
        key: 2,
        label: "RU",
      },
      {
        key: 4,
        label: "UK",
      },
    ];
  },
  getAbrByLanguage: (state, getter) => ({ language, defaultValue }) => {
    return _.get(getter.languageAbr, language, defaultValue);
  },
  availableAges: () => ["18-24", "25-34", "35-44", "45plus"],
  availableStatuses: () => [
    "active",
    "pending",
    "blocked",
    "banned",
    "frozen",
    "archive",
  ],

  statusColor: () => {
    return {
      active: "green",
      pending: "indigo",
      blocked: "deep-orange",
      banned: "red",
      history: "grey",
      frozen: "light-blue",
    };
  },
  getColorByStatus: (state, getter) => ({ status, defaultValue }) => {
    return _.get(getter.statusColor, status, defaultValue);
  },

  availableStages: () => [0, 2, 3, 4],
  hasAppliedFilters: (state, getters) => !_.isEmpty(getters.formattedFilters),

  privacySettings: () => {
    return {
      byear: [SETTINGS_ONLY_ME],
      ownPostsVisibility: [SETTINGS_PUBLIC, SETTINGS_FRIENDS, SETTINGS_ONLY_ME],
      friendsListVisibility: [
        SETTINGS_PUBLIC,
        SETTINGS_FRIENDS,
        SETTINGS_ONLY_ME,
      ],
      followingsVisibility: [
        SETTINGS_PUBLIC,
        SETTINGS_FRIENDS,
        SETTINGS_ONLY_ME,
      ],
      followersVisibility: [
        SETTINGS_PUBLIC,
        SETTINGS_FRIENDS,
        SETTINGS_ONLY_ME,
      ],
      incomingFriendRequests: [SETTINGS_EVERYONE, SETTINGS_FRIENDS_OF_FRIENDS],
      allowFollowers: [SETTINGS_PUBLIC, SETTINGS_FRIENDS],
      allowPostComments: [
        SETTINGS_PUBLIC,
        SETTINGS_FRIENDS,
        SETTINGS_FRIENDS_OF_FRIENDS,
      ],
      profileInfo: [
        SETTINGS_PUBLIC,
        SETTINGS_FRIENDS,
        SETTINGS_FRIENDS_OF_FRIENDS,
      ],
      allowTimelinePosts: [SETTINGS_FRIENDS, SETTINGS_ONLY_ME],
      taggedPostsReview: [SETTINGS_ON, SETTINGS_OFF],
      taggedPostsVisibility: [
        SETTINGS_EVERYONE,
        SETTINGS_FRIENDS_OF_FRIENDS,
        SETTINGS_FRIENDS,
        SETTINGS_ONLY_ME,
      ],
      otherPostsVisibility: [
        SETTINGS_EVERYONE,
        SETTINGS_FRIENDS_OF_FRIENDS,
        SETTINGS_FRIENDS,
        SETTINGS_ONLY_ME,
      ],
      ownPostsTagsReview: [SETTINGS_ON, SETTINGS_OFF],
      taggedPostsAudience: [SETTINGS_ONLY_ME, SETTINGS_FRIENDS],
    };
  },
  availablePrivacySettings: (state, getter) => (settingsItem) => {
    return _.get(getter.privacySettings, settingsItem, []);
  },
  availableAttributes: () => [
    "fullname",
    "domain",
    "birthday",
    "age",
    "userId",
    "gender",
    "language",
    "login",
    "password",
    "type",
    "enabled",
    "stage",
    "errorCode",
    "status",
    "proxy",
    "tags",
  ],
};
