import _ from "lodash";

export const emptyFilters = {
  id: null,
  title: null,
};

export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});
export const state = {
  items: [], //List of services with base info only
  children: {}, //List of services with base info only
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: -1,
    totalItems: 0,
  },
  sort: {
    //Ordering state, attribute & direction
    sortBy: "level",
    desc: false,
  },
  filters: _.merge({}, defaultFilters),
  emptyFilters,
  defaultFilters,
};
