<template>
  <v-app>
    <overlay v-if="isNotAuthenticated"></overlay>
    <nav-menu></nav-menu>
    <v-main class="grey lighten-4">
      <v-container class="spacing-playground pa-7" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <notifications group="messages" position="bottom right" />
    <notifications group="error" position="bottom right" />
  </v-app>
</template>

<script>
import NavMenu from "@/views/layouts/parts/Nav";
import Overlay from "@/views/layouts/parts/Overlay";

export default {
  name: "Default",
  components: {
    NavMenu,
    Overlay,
  },
  computed: {
    isNotAuthenticated: function () {
      return !this.$store.getters.isAuthenticated;
    },
  },
};
</script>
