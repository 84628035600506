import _ from "lodash";

export const excludedFilters = ["reportChangedRange", "scope"];

export const emptyFilters = {
  scope: null,
  reportChangedRange: null,
  reportDateFrom: null,
  reportDateTo: null,
  gobellsId: null,
  userId: null,
  attribute: null,
  oldValue: null,
  newValue: null,
  currentDomain: null,
  currentStatus: null,
  currentStage: null,
  currentType: null,
};

export const defaultFilters = _.merge({}, emptyFilters, {});

export const state = {
  items: [], //List of donors with base info only
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
  },
  filters: defaultFilters,
  emptyFilters,
};
