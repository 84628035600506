export const FETCH_TAG_CATEGORIES = "FETCH_TAG_CATEGORIES";
export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_PARTIAL = "FETCH_TAGS_PARTIAL";

export const PATCH_TAG = "PATCH_TAG";
export const DELETE_TAG = "DELETE_TAG";

export const MARK_ACCOUNTS = "MARK_ACCOUNTS";

export const SET_FILTER = "SET_FILTER";
