export const state = {
  items: [], //List of services with base info only
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
  },
  sort: {
    //Ordering state, attribute & direction
    sortBy: "orderNum",
    desc: false,
  },
};
