export const SET_ACCOUNTS_HISTORY_STAT = "SET_ACCOUNTS_HISTORY_STAT";

import _ from "lodash";

export default {
  [SET_ACCOUNTS_HISTORY_STAT]: (state, data) => {
    state.accountsHistoryStatistics = _.orderBy(
      data.batch,
      ["reportDate"],
      ["asc"]
    );
  },
};
