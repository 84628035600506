import store from "@/store";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/identity";
import router from "@/router";

import _ from "lodash";

import { apiRoutes } from "@/config/api.config";

const downloadFile = function ({ url, filename }) {
  let downloadUrl = _.template(apiRoutes.host + url);
  return new Promise((resolve, reject) => {
    try {
      let headers = {};
      let token = store.getters["token"];
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      Vue.axios({
        url: downloadUrl(),
        method: "GET",
        responseType: "blob", // important
        headers,
      }).then(
        (response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            store.commit(AUTH_LOGOUT);
            router.go({ name: "Dashboard" });
          } else {
            resolve(error.response);
          }
        }
      );
    } catch (err) {
      reject(new Error(err));
    }
  });
};

export default downloadFile;
