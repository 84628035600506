import _ from "lodash";

export const SCOPE_FACEBOOK = "facebook";
export const SCOPE_TWITTER = "twitter";
export const SCOPE_YOUTUBE = "youtube";
export const SCOPE_INSTAGRAM = "instagram";
export const SCOPE_LINKEDIN = "linkedin";
export const SCOPE_VKONTAKTE = "vkontakte";

export const getters = {
  //Base getters
  items: (state) => state.items,
  itemsDetails: (state) => state.itemDetails,
  pagination: (state) => state.pagination,

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,
  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),

  //Formatters
  formattedSort: (state) =>
    state.sort.sortBy ? (state.sort.desc ? "-" : "") + state.sort.sortBy : null,

  scopes: (state, getters) =>
    _.map(
      getters.items.filter(function (item) {
        return parseInt(item.settings.hasAccounts);
      }),
      "scope"
    ),
  getServiceName: (state, getter) => (servicePart) => {
    const service = _.find(getter.items, servicePart);
    return service ? service.title : servicePart.scope;
  },
};
