<template>
  <v-list-group :value="selected" :prepend-icon="item.icon">
    <template v-slot:activator>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </template>
    <component
      v-for="(linkItem, i) in item.nested"
      :is="linkItem.nested ? 'nav-group' : 'nav-item'"
      :key="i"
      :item="linkItem"
      color="primary"
      class="ml-2"
    ></component>
  </v-list-group>
</template>

<script>
import NavItem from "@/views/layouts/parts/NavItem";
export default {
  name: "NavGroup",
  components: { NavItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: Boolean,
  },
  data: function () {
    return {
      selectedItem: null,
    };
  },
  computed: {
    plainMenuMap: function () {
      const getNestedRecursive = function (nestedItems) {
        console.log(nestedItems);
        return nestedItems;
      };
      let items = {};
      this.item.nested.forEach(function (item, index) {
        if (item.nested !== undefined) {
          const nestedItems = getNestedRecursive(item.nested);
          nestedItems.forEach(function (nestedItem) {
            items[nestedItem.route.name] = index;
          });
        } else {
          items[item.route.name] = item.route.name;
        }
      });
      return items;
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.selectedItem = this.plainMenuMap[this.$route.name];
      },
      deep: true,
    },
  },
  mounted() {
    this.selectedItem = this.plainMenuMap[this.$route.name];
  },
};
</script>

<style scoped>
.theme--light.v-divider {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
}
</style>
