import { FETCH_PROJECT_STATUS } from "@/store/modules/project-statistics/actions";

export const RESET_FILTERS = "RESET_FILTERS";

export const mutations = {
  [FETCH_PROJECT_STATUS]: (state, data) => {
    state.items = data.batch;
  },
  [RESET_FILTERS]: (state, filters) => {
    state.filters = filters;
    state.pagination.page = 1;
  },
};
