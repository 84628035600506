import { FETCH_ACCOUNT_GENERATION_DETAILS } from "@/store/actions/account-generation-details";
import apiCall from "@/utils/api";
import { API_FETCH_ACCOUNT_GENERATION_DETAILS } from "@/config/api.config";

export const actions = {
  [FETCH_ACCOUNT_GENERATION_DETAILS]: ({ commit, getters }, { id }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ACCOUNT_GENERATION_DETAILS,
        parts: { id },
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            commit(FETCH_ACCOUNT_GENERATION_DETAILS, payload.data);
          }
          resolve(payload);
        })
        .catch((err) => reject(err));
    });
  },
};
