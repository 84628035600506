import _ from "lodash";

export const emptyGenerationParams = {
  ageGroup: null,
  city: null,
  gender: null,
  language: null,
  numGoal: null,
};

export const paramsKeys = ["ageGroup", "city", "gender", "language", "numGoal"];

export const defaultGenerationParams = _.merge({}, emptyGenerationParams, {});
export const state = {
  params: defaultGenerationParams,
  items: [],
  generation: [],
  cities: [],
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 20,
    totalItems: 0,
  },
};
