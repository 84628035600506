//Import store action constants
import {
  FETCH_ACCOUNT_HISTORIES,
  EXPORT_ACCOUNT_HISTORIES,
} from "@/store/actions/account-histories";

//Import base api helper
import apiCall from "@/utils/api";

import {
  API_EXPORT_ACCOUNT_HISTORIES,
  API_FETCH_ACCOUNT_HISTORIES,
} from "@/config/api.config";

export const actions = {
  [FETCH_ACCOUNT_HISTORIES]: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ACCOUNT_HISTORIES,
        params: {
          scope: getters.scope,
          limit: getters.itemsPerPage,
          offset: getters.offset,
          search: getters.search,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_ACCOUNT_HISTORIES, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EXPORT_ACCOUNT_HISTORIES]: ({ getters }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_EXPORT_ACCOUNT_HISTORIES,
        data: {
          search: getters.formattedFilters,
          scope: getters.scope,
          sort: getters.formattedSort,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
