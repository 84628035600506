import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_RECOVERY_PASSWORD,
  USER_REQUEST,
  USER_SUCCESS,
  AUTH_VALIDATE_RECOVERY_CODE,
  AUTH_RESET_PASSWORD,
} from "@/store/actions/identity";
import apiCall from "@/utils/api";

import {
  API_LOGOUT,
  API_VERIFY_CODE,
  API_RESET_PASSWORD,
} from "@/config/api.config";

export const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      //Call auth/login API method
      apiCall({ data: user, method: "auth" })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            commit(AUTH_SUCCESS, payload.data);
            dispatch(USER_REQUEST);
          } else {
            commit(AUTH_ERROR);
          }
          resolve(payload);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_RECOVERY_PASSWORD]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({ data: user, method: "sendCode" })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            commit(AUTH_RECOVERY_PASSWORD, payload.data);
          } else {
            commit(AUTH_ERROR);
          }
          resolve(payload);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_VALIDATE_RECOVERY_CODE]: ({ commit }, { email, resetToken }) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_VERIFY_CODE,
        data: { email, resetToken },
      })
        .then((response) => {
          let payload = response.data;
          resolve(payload);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_RESET_PASSWORD]: ({ commit }, { email, resetToken, password }) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_RESET_PASSWORD,
        data: { email, resetToken, password },
      })
        .then((response) => {
          let payload = response.data;
          resolve(payload);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [USER_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      //Call GET /private API method
      apiCall({ method: "private" })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            if (response.status && payload.data) {
              //Commit changes to store
              commit(USER_SUCCESS, payload.data);
              resolve(payload.data);
            }
          }
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        data: { refreshToken: getters.refreshToken },
        method: API_LOGOUT,
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status) {
            commit(AUTH_LOGOUT);
          } else {
            commit(AUTH_ERROR);
          }
          resolve(payload);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
};
