import Vue from "vue";

//Import mutation actions constants
import {
  FETCH_TAG_CATEGORIES,
  FETCH_TAGS,
  SET_FILTER,
} from "@/store/actions/tag";

export const mutations = {
  //Store current page
  [FETCH_TAG_CATEGORIES]: (state, data) => {
    state.items = data.batch;
    state.pagination.totalItems = data.total;
  },
  //Store current page
  [FETCH_TAGS]: (state, data) => {
    Vue.set(state.children, data.parentId, data.batch);
  },
  //Store current page
  [SET_FILTER]: (state, filters) => {
    state.pagination.page = 1;
    state.filters = filters;
  },
};
