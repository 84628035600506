import {
  FETCH_ACCOUNT_HISTORIES,
  SET_FILTER,
} from "@/store/actions/account-histories";
import Vue from "vue";

export const mutations = {
  [FETCH_ACCOUNT_HISTORIES]: (state, data) => {
    Vue.set(state, "items", data.batch);
    Vue.set(state.pagination, "totalItems", data.total);
  },
  [SET_FILTER]: (state, filters) => {
    state.pagination.page = 1;
    state.filters = filters;
  },
};
