//Import mutation actions constants
import {
  FETCH_PACKAGES,
  FETCH_PACKAGE,
  SET_FILTER,
} from "@/store/actions/proxy-package";

export const mutations = {
  //Store list package & pagination params
  [FETCH_PACKAGES]: (state, data) => {
    state.items = data.batch;
    state.pagination.totalItems = data.total;
  },
  //Store package details
  [FETCH_PACKAGE]: (state, data) => {
    state.itemDetails["ID_" + data.id] = data;
  },
  //Store current filters
  [SET_FILTER]: (state, data) => {
    state.filters = data;
    state.pagination.page = 1;
  },
};
