import _ from "lodash";

import {
  ADD_CONTENT_RECOMMENDER_FILTER,
  REMOVE_CONTENT_RECOMMENDER_FILTER,
  SET_DEFAULT_CONTENT_RECOMMENDER_FILTER,
  SET_CONTENT_RECOMMENDER_FILTER,
  ADD_FRIENDS_RECOMMENDER_FILTER,
  REMOVE_FRIENDS_RECOMMENDER_FILTER,
  SET_DEFAULT_FRIENDS_RECOMMENDER_FILTER,
  SET_FRIENDS_RECOMMENDER_FILTER,
  ADD_VECTOR_FILTER,
  REMOVE_VECTOR_FILTER,
  SET_DEFAULT_VECTOR_FILTER,
  SET_VECTOR_FILTER,
  ADD_ABUSE_RECOMMENDER_FILTER,
  REMOVE_ABUSE_RECOMMENDER_FILTER,
  SET_DEFAULT_ABUSE_RECOMMENDER_FILTER,
  SET_ABUSE_RECOMMENDER_FILTER,
  ADD_AUDIENCE_RECOMMENDER_FILTER,
  REMOVE_AUDIENCE_RECOMMENDER_FILTER,
  SET_DEFAULT_AUDIENCE_RECOMMENDER_FILTER,
  SET_AUDIENCE_RECOMMENDER_FILTER,
  ADD_ACCOUNT_FILTER,
  REMOVE_ACCOUNT_FILTER,
  SET_DEFAULT_ACCOUNT_FILTER,
  SET_ACCOUNT_FILTER,
  ADD_FACEBOOK_EMULATION_SETTINGS_FILTER,
  REMOVE_FACEBOOK_EMULATION_SETTINGS_FILTER,
  SET_DEFAULT_FACEBOOK_EMULATION_SETTINGS_FILTER,
  SET_FACEBOOK_EMULATION_SETTINGS_FILTER,
  ADD_ACCOUNT_HISTORIES_FILTER,
  REMOVE_ACCOUNT_HISTORIES_FILTER,
  SET_DEFAULT_ACCOUNT_HISTORIES_FILTER,
  SET_ACCOUNT_HISTORIES_FILTER,
} from "@/store/actions/persistentStorage";

import {
  availableContentRecommenderFilters,
  defaultContentRecommenderFilters,
  availableFriendsRecommenderFilters,
  defaultFriendsRecommenderFilters,
  availableVectorFilters,
  defaultVectorFilters,
  availableAudienceRecommenderFilters,
  defaultAudienceRecommenderFilters,
  availableAbuseRecommenderFilters,
  defaultAbuseRecommenderFilters,
  availableAccountFilters,
  defaultAccountFilters,
  availableEmulationSettingsFilters,
  defaultEmulationSettingsFilters,
  availableAccountHistoriesFilters,
  defaultAccountHistoriesFilters,
} from "@/store/modules/persistentStorage/state";

export const mutations = {
  [ADD_CONTENT_RECOMMENDER_FILTER]: (state, filter) => {
    if (availableContentRecommenderFilters.indexOf(filter) > -1) {
      state.contentRecommenderFilters.push(filter);
    }
  },
  [REMOVE_CONTENT_RECOMMENDER_FILTER]: (state, filter) => {
    const index = state.contentRecommenderFilters.indexOf(filter);
    if (index > -1) {
      state.contentRecommenderFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_CONTENT_RECOMMENDER_FILTER]: (state) => {
    state.contentRecommenderFilters = _.merge(
      [],
      defaultContentRecommenderFilters
    );
  },
  [SET_CONTENT_RECOMMENDER_FILTER]: (state, filters) => {
    state.contentRecommenderFilters = filters;
  },
  [ADD_VECTOR_FILTER]: (state, filter) => {
    if (availableVectorFilters.indexOf(filter) > -1) {
      state.vectorFilters.push(filter);
    }
  },
  [ADD_FRIENDS_RECOMMENDER_FILTER]: (state, filter) => {
    if (availableFriendsRecommenderFilters.indexOf(filter) > -1) {
      state.friendsRecommenderFilters.push(filter);
    }
  },
  [REMOVE_FRIENDS_RECOMMENDER_FILTER]: (state, filter) => {
    const index = state.friendsRecommenderFilters.indexOf(filter);
    if (index > -1) {
      state.friendsRecommenderFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_FRIENDS_RECOMMENDER_FILTER]: (state) => {
    state.friendsRecommenderFilters = _.merge(
      [],
      defaultFriendsRecommenderFilters
    );
  },
  [SET_FRIENDS_RECOMMENDER_FILTER]: (state, filters) => {
    state.friendsRecommenderFilters = filters;
  },
  [ADD_VECTOR_FILTER]: (state, filter) => {
    if (availableVectorFilters.indexOf(filter) > -1) {
      state.vectorFilters.push(filter);
    }
  },
  [REMOVE_VECTOR_FILTER]: (state, filter) => {
    const index = state.vectorFilters.indexOf(filter);
    if (index > -1) {
      state.vectorFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_VECTOR_FILTER]: (state) => {
    state.vectorFilters = _.merge([], defaultVectorFilters);
  },
  [SET_VECTOR_FILTER]: (state, filters) => {
    state.vectorFilters = filters;
  },
  [ADD_AUDIENCE_RECOMMENDER_FILTER]: (state, filter) => {
    if (availableAudienceRecommenderFilters.indexOf(filter) > -1) {
      state.audienceRecommenderFilters.push(filter);
    }
  },
  [REMOVE_AUDIENCE_RECOMMENDER_FILTER]: (state, filter) => {
    const index = state.audienceRecommenderFilters.indexOf(filter);
    if (index > -1) {
      state.audienceRecommenderFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_AUDIENCE_RECOMMENDER_FILTER]: (state) => {
    state.audienceRecommenderFilters = _.merge(
      [],
      defaultAudienceRecommenderFilters
    );
  },
  [SET_AUDIENCE_RECOMMENDER_FILTER]: (state, filters) => {
    state.audienceRecommenderFilters = filters;
  },
  [ADD_ABUSE_RECOMMENDER_FILTER]: (state, filter) => {
    if (availableAbuseRecommenderFilters.indexOf(filter) > -1) {
      state.abuseRecommenderFilters.push(filter);
    }
  },
  [REMOVE_ABUSE_RECOMMENDER_FILTER]: (state, filter) => {
    const index = state.abuseRecommenderFilters.indexOf(filter);
    if (index > -1) {
      state.abuseRecommenderFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_ABUSE_RECOMMENDER_FILTER]: (state) => {
    state.abuseRecommenderFilters = _.merge([], defaultAbuseRecommenderFilters);
  },
  [SET_ABUSE_RECOMMENDER_FILTER]: (state, filters) => {
    state.abuseRecommenderFilters = filters;
  },
  [ADD_ACCOUNT_FILTER]: (state, filter) => {
    if (availableAccountFilters.indexOf(filter) > -1) {
      state.accountFilters.push(filter);
    }
  },
  [REMOVE_ACCOUNT_FILTER]: (state, filter) => {
    const index = state.accountFilters.indexOf(filter);
    if (index > -1) {
      state.accountFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_ACCOUNT_FILTER]: (state) => {
    state.accountFilters = _.merge([], defaultAccountFilters);
  },
  [SET_ACCOUNT_FILTER]: (state, filters) => {
    state.accountFilters = filters;
  },
  [ADD_FACEBOOK_EMULATION_SETTINGS_FILTER]: (state, filter) => {
    if (availableEmulationSettingsFilters.indexOf(filter) > -1) {
      state.facebookEmulationSettingsFilters.push(filter);
    }
  },
  [REMOVE_FACEBOOK_EMULATION_SETTINGS_FILTER]: (state, filter) => {
    const index = state.facebookEmulationSettingsFilters.indexOf(filter);
    if (index > -1) {
      state.facebookEmulationSettingsFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_FACEBOOK_EMULATION_SETTINGS_FILTER]: (state) => {
    state.facebookEmulationSettingsFilters = _.merge(
      [],
      defaultEmulationSettingsFilters
    );
  },
  [SET_FACEBOOK_EMULATION_SETTINGS_FILTER]: (state, filters) => {
    state.facebookEmulationSettingsFilters = filters;
  },
  [ADD_ACCOUNT_HISTORIES_FILTER]: (state, filter) => {
    if (availableAccountHistoriesFilters.indexOf(filter) > -1) {
      state.accountHistoriesFilters.push(filter);
    }
  },
  [REMOVE_ACCOUNT_HISTORIES_FILTER]: (state, filter) => {
    const index = state.accountHistoriesFilters.indexOf(filter);
    if (index > -1) {
      state.accountHistoriesFilters.splice(index, 1);
    }
  },
  [SET_DEFAULT_ACCOUNT_HISTORIES_FILTER]: (state) => {
    state.accountHistoriesFilters = _.merge([], defaultAccountHistoriesFilters);
  },
  [SET_ACCOUNT_HISTORIES_FILTER]: (state, filters) => {
    state.accountHistoriesFilters = filters;
  },
};
