//Auth routes
export const API_LOGIN = "auth"; //Get new pair access&refresh tokens
export const API_SEND_CODE = "sendCode"; //Send verification code for reset password
export const API_VERIFY_CODE = "verifyCode"; //Send verification code for reset password
export const API_RESET_PASSWORD = "resetPassword"; //Send verification code for reset password
export const API_LOGOUT = "logout"; //Send verification code for reset password

//Private routes
export const API_PROFILE = "private"; //Get current profile info

//Proxy packages
export const API_FETCH_PROXY_PACKAGES = "fetchProxyPackages"; //Get list of packages
export const API_FETCH_PROXY_PACKAGE = "fetchProxyPackage"; //Get package details
export const API_PATCH_PROXY_PACKAGE = "patchProxyPackage"; //Update package
export const API_SYNC_PROXY_PACKAGE = "syncProxyPackage"; //Sync package with social
export const API_ACTIVATE_PROXY_PACKAGE = "activateProxyPackage"; //Sync package with social

//Proxy packages meta
export const API_FETCH_PROXY_PACKAGE_META = "fetchProxyPackageMeta"; // Get list of available packages
export const API_FETCH_PROXY_PACKAGE_SOURCE_META =
  "fetchProxyPackageSourceMeta"; //Get list of existing packages sources
export const API_FETCH_TAG_META = "fetchTagMeta"; //Get list of existing packages sources
export const API_FETCH_REGION_META = "fetchRegionMeta"; //Get list of existing packages sources
export const API_FETCH_ACCOUNT_TYPES = "fetchAccountTypes"; //Get list of account types

//Proxy route
export const API_FETCH_PROXIES = "fetchProxies"; //Get list of proxies
export const API_FETCH_PROXY = "fetchProxy"; //Get proxy details
export const API_PATCH_PROXY = "patchProxy"; //Update proxy
export const API_SYNC_PROXIES = "syncProxies"; //Sync all proxies with social
export const API_SYNC_PROXY = "syncProxy"; //Sync specified proxy with social

//Accounts routes
export const API_FETCH_ACCOUNTS = "fetchAccounts"; //Get list of accounts
export const API_EXPORT_ACCOUNTS = "exportAccounts"; //Get file with list of accounts
export const API_SYNC_ACCOUNTS = "syncAccounts"; //Get file with list of accounts
export const API_SYNC_ACCOUNT = "syncAccount"; //Get file with list of accounts
export const API_FETCH_DONOR_ACCOUNT = "fetchDonorAccounts"; //Get list of accounts
export const API_FETCH_ACCOUNT_DETAILS = "fetchAccountDetails"; //Get list of accounts
export const API_FETCH_ACCOUNT_SETTINGS = "fetchAccountSettings"; //Get list of accounts
export const API_PATCH_ACCOUNT_SETTINGS = "patchAccountSettings"; //Get list of accounts
export const API_FETCH_ACCOUNT_HISTORY = "fetchAccountHistory"; //Get list of accounts
export const API_FETCH_ACCOUNT_STATISTIC = "fetchAccountStat"; //Get list of accounts
export const API_FETCH_ACCOUNT_HISTORIES = "fetchAccountHistories"; //Get list of accounts last changes
export const API_EXPORT_ACCOUNT_HISTORIES = "exportAccountHistories"; //Get list of accounts last changes

export const API_PATCH_DONOR = "patchDonor"; //Get list of accounts
export const API_PATCH_ACCOUNT = "patchAccount"; //Get list of accounts

//Service routes
export const API_FETCH_SERVICES = "fetchServices"; //Get list of accounts
export const API_PUT_SERVICE = "putServices"; //Get list of accounts
export const API_PATCH_SERVICE = "patchServices"; //Get list of accounts
export const API_DELETE_SERVICE = "deleteServices"; //Get list of accounts

//User management routes
export const API_FETCH_USERS = "fetchUsers"; //Fetch user list
export const API_PATCH_USER = "patchUser"; //Update user
export const API_PUT_USER = "putUser"; //Create user
export const API_DELETE_USER = "deleteUser"; //Delete user

//Recommenders
export const API_FETCH_AUDIENCE_SETTINGS = "fetchAudienceSettings";
export const API_PUT_AUDIENCE_SETTINGS = "putAudienceSettings";
export const API_PATCH_AUDIENCE_SETTINGS = "patchAudienceSettings";
export const API_DELETE_AUDIENCE_SETTINGS = "deleteAudienceSettings";
export const API_ACTIVATE_AUDIENCE_SETTINGS = "activateAudienceSettings";

export const API_FETCH_ABUSE_SETTINGS = "fetchAbuseSettings";
export const API_PUT_ABUSE_SETTINGS = "putAbuseSettings";
export const API_PATCH_ABUSE_SETTINGS = "patchAbuseSettings";
export const API_DELETE_ABUSE_SETTINGS = "deleteAbuseSettings";
export const API_ACTIVATE_ABUSE_SETTINGS = "activateAbuseSettings";

export const API_FETCH_BLACKLIST = "fetchBlacklist";
export const API_PUT_BLACKLIST = "putBlacklist";
export const API_PATCH_BLACKLIST = "patchBlacklist";
export const API_DELETE_BLACKLIST = "deleteBlacklist";

export const API_FETCH_BLACKLIST_ITEMS = "fetchBlacklistItems";
export const API_PUT_BLACKLIST_ITEMS = "putBlacklistItems";
export const API_PATCH_BLACKLIST_ITEMS = "patchBlacklistItems";
export const API_DELETE_BLACKLIST_ITEMS = "deleteBlacklistItems";

export const API_FETCH_VECTORS = "fetchVectors";
export const API_PUT_VECTOR = "putVector";
export const API_PATCH_VECTOR = "patchVector";
export const API_DELETE_VECTOR = "deleteVector";

export const API_FETCH_CONTENT_SETTINGS = "fetchContentSettings";
export const API_PUT_CONTENT_SETTINGS = "putContentSettings";
export const API_PATCH_CONTENT_SETTINGS = "patchContentSettings";
export const API_DELETE_CONTENT_SETTINGS = "deleteContentSettings";

export const API_FETCH_FRIENDS_SETTINGS = "fetchFriendsSettings";
export const API_PUT_FRIENDS_SETTINGS = "putFriendsSettings";
export const API_PATCH_FRIENDS_SETTINGS = "patchFriendsSettings";
export const API_DELETE_FRIENDS_SETTINGS = "deleteFriendsSettings";

export const API_FETCH_TAGS = "fetchTags";
export const API_PUT_TAG = "putTag";
export const API_PATCH_TAG = "patchTag";
export const API_DELETE_TAG = "deleteTag";
export const API_MARK_ACCOUNTS = "markAccounts";

export const API_FETCH_EMULATION_SETTINGS = "fetchEmulationSettings";
export const API_PUT_EMULATION_SETTINGS = "putEmulationSettings";
export const API_PATCH_EMULATION_SETTINGS = "patchEmulationSettings";
export const API_DELETE_EMULATION_SETTINGS = "deleteEmulationSettings";
export const API_SYNC_EMULATION_SETTINGS = "syncEmulationSettings";

export const API_FETCH_ACCOUNTS_HISTORY = "fetchAccountsHistoryStatistics";
export const API_FETCH_TASK_STATISTICS = "fetchTaskStatistics";
export const API_FETCH_CONTENT_STATISTICS = "fetchContentStatistics";
export const API_FETCH_LINK_STATISTICS = "fetchLinkStatistics";
export const API_FETCH_MARKER_STATISTICS = "fetchMarkerStatistics";
export const API_FETCH_PROXY_STATISTICS = "fetchProxyStatistics";
export const API_FETCH_PROXY_PACKAGE_STATISTICS = "fetchProxyPackageStatistics";
export const API_FETCH_SYNCHRONIZATION_TASK_STATISTICS =
  "fetchSynchronizationTaskStatistics";
export const API_FETCH_SYNCHRONIZATION_TASK_SCHEDULES_STATISTICS =
  "fetchSynchronizationTaskSchedulesStatistics";

export const API_FETCH_PROJECT_STATUS = "fetchProjectStatus";
export const API_TASK_QUEUE_STATUS = "taskQueueStatus";
export const API_EMULATION_DETAILS = "emulationDetails";
export const API_EMULATION_SUMMARY = "emulationSummary";

// Data generation
export const API_FETCH_ACCOUNT_GENERATIONS = "fetchAccountGenerations";
export const API_FETCH_ACCOUNT_GENERATION_DETAILS =
  "fetchAccountGenerationDetails";
export const API_EXPORT_ACCOUNT_GENERATION = "exportAccountGeneration";

export const API_CALCULATE_ACCOUNT_GENERATION = "calculateAccountGeneration";
export const API_PUT_ACCOUNT_GENERATION = "putAccountGeneration";

export const API_FETCH_GENERATION_CITIES = "fetchGenerationCities";

export const apiRoutes = {
  host: process.env.VUE_APP_API_HOST,
  methods: {
    [API_LOGIN]: {
      url: "/auth/login",
      httpMethod: "POST",
    },
    [API_LOGOUT]: {
      url: "/auth/logout",
      httpMethod: "POST",
    },
    [API_SEND_CODE]: {
      url: "/auth/recovery",
      httpMethod: "POST",
    },
    [API_VERIFY_CODE]: {
      url: "/auth/validate-reset-password-token",
      httpMethod: "POST",
    },
    [API_RESET_PASSWORD]: {
      url: "/auth/reset",
      httpMethod: "POST",
    },
    [API_PROFILE]: {
      url: "/private",
      httpMethod: "GET",
    },
    //Proxy Packages routes
    [API_FETCH_PROXY_PACKAGES]: {
      url: "/proxy-packages",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY_PACKAGE]: {
      url: "/proxy-package/${id}",
      httpMethod: "GET",
    },
    [API_PATCH_PROXY_PACKAGE]: {
      url: "/proxy-package/${id}",
      httpMethod: "PATCH",
    },
    [API_SYNC_PROXY_PACKAGE]: {
      url: "/proxy-package/${id}/sync",
      httpMethod: "POST",
    },
    [API_ACTIVATE_PROXY_PACKAGE]: {
      url: "/proxy-package/${id}/activate",
      httpMethod: "POST",
    },
    [API_FETCH_PROXY_PACKAGE_META]: {
      url: "/meta/proxy-package",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY_PACKAGE_SOURCE_META]: {
      url: "/meta/proxy-package-source",
      httpMethod: "GET",
    },
    [API_FETCH_TAG_META]: {
      url: "/meta/tag",
      httpMethod: "GET",
    },
    [API_FETCH_REGION_META]: {
      url: "/meta/region",
      httpMethod: "GET",
    },
    //Proxy routes
    [API_FETCH_PROXIES]: {
      url: "/proxies",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY]: {
      url: "/proxy/${id}",
      httpMethod: "GET",
    },
    [API_SYNC_PROXY]: {
      url: "/proxy/${id}/sync",
      httpMethod: "POST",
    },
    [API_SYNC_PROXIES]: {
      url: "/proxies/sync",
      httpMethod: "POST",
    },
    [API_PATCH_PROXY]: {
      url: "/proxy/${id}",
      httpMethod: "PATCH",
    },
    [API_FETCH_ACCOUNTS]: {
      url: "/accounts",
      httpMethod: "GET",
    },
    [API_EXPORT_ACCOUNTS]: {
      url: "/accounts/export",
      httpMethod: "POST",
    },
    [API_SYNC_ACCOUNTS]: {
      url: "/account/${scope}/sync",
      httpMethod: "POST",
    },
    [API_SYNC_ACCOUNT]: {
      url: "/account/${gobellsId}/${scope}/${userId}/sync",
      httpMethod: "POST",
    },
    [API_FETCH_DONOR_ACCOUNT]: {
      url: "/account/${gobellsId}",
      httpMethod: "GET",
    },
    [API_FETCH_ACCOUNT_DETAILS]: {
      url: "/account/${gobellsId}/${type}/${userId}",
      httpMethod: "GET",
    },
    [API_FETCH_ACCOUNT_SETTINGS]: {
      url: "/account-settings/${gobellsId}/${type}/${userId}",
      httpMethod: "GET",
    },
    [API_PATCH_ACCOUNT_SETTINGS]: {
      url: "/account-settings/${gobellsId}/${type}/${userId}",
      httpMethod: "PATCH",
    },
    [API_FETCH_ACCOUNT_HISTORY]: {
      url: "/account-history/${gobellsId}/${scope}/${userId}",
      httpMethod: "GET",
    },
    [API_FETCH_ACCOUNT_STATISTIC]: {
      url: "/account-statistic/${gobellsId}/${scope}/${userId}",
      httpMethod: "GET",
    },
    [API_FETCH_SERVICES]: {
      url: "/services",
      httpMethod: "GET",
    },
    [API_PUT_SERVICE]: {
      url: "/service",
      httpMethod: "PUT",
    },
    [API_PATCH_SERVICE]: {
      url: "/service/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_SERVICE]: {
      url: "/service/${id}",
      httpMethod: "DELETE",
    },
    [API_PATCH_DONOR]: {
      url: "/account/${donorId}",
      httpMethod: "PATCH",
    },
    [API_PATCH_ACCOUNT]: {
      url: "/account/${gobellsId}/${scope}/${userId}",
      httpMethod: "PATCH",
    },
    [API_FETCH_USERS]: {
      url: "/users",
      httpMethod: "GET",
    },
    [API_PATCH_USER]: {
      url: "/user/${id}",
      httpMethod: "PATCH",
    },
    [API_PUT_USER]: {
      url: "/user",
      httpMethod: "PUT",
    },
    [API_DELETE_USER]: {
      url: "/user/${id}",
      httpMethod: "DELETE",
    },
    [API_FETCH_AUDIENCE_SETTINGS]: {
      url: "/audience-settings",
      httpMethod: "GET",
    },
    [API_PUT_AUDIENCE_SETTINGS]: {
      url: "/audience-settings",
      httpMethod: "PUT",
    },
    [API_PATCH_AUDIENCE_SETTINGS]: {
      url: "/audience-settings/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_AUDIENCE_SETTINGS]: {
      url: "/audience-settings/${id}",
      httpMethod: "DELETE",
    },
    [API_ACTIVATE_AUDIENCE_SETTINGS]: {
      url: "/audience-settings/${id}/status",
      httpMethod: "PATCH",
    },
    [API_FETCH_ABUSE_SETTINGS]: {
      url: "/abuse-settings",
      httpMethod: "GET",
    },
    [API_PUT_ABUSE_SETTINGS]: {
      url: "/abuse-settings",
      httpMethod: "PUT",
    },
    [API_PATCH_ABUSE_SETTINGS]: {
      url: "/abuse-settings/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_ABUSE_SETTINGS]: {
      url: "/abuse-settings/${id}",
      httpMethod: "DELETE",
    },
    [API_ACTIVATE_ABUSE_SETTINGS]: {
      url: "/abuse-settings/${id}/status",
      httpMethod: "PATCH",
    },
    [API_FETCH_BLACKLIST]: {
      url: "/blacklists",
      httpMethod: "GET",
    },
    [API_PUT_BLACKLIST]: {
      url: "/blacklist",
      httpMethod: "PUT",
    },
    [API_PATCH_BLACKLIST]: {
      url: "/blacklist/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_BLACKLIST]: {
      url: "/blacklist/${id}",
      httpMethod: "DELETE",
    },
    [API_FETCH_BLACKLIST_ITEMS]: {
      url: "/blacklist/${id}/items",
      httpMethod: "GET",
    },
    [API_PUT_BLACKLIST_ITEMS]: {
      url: "/blacklist/${id}/items",
      httpMethod: "PUT",
    },
    [API_PATCH_BLACKLIST_ITEMS]: {
      url: "/blacklist/${id}/items",
      httpMethod: "PATCH",
    },
    [API_DELETE_BLACKLIST_ITEMS]: {
      url: "/blacklist/${id}/items",
      httpMethod: "DELETE",
    },
    [API_FETCH_VECTORS]: {
      url: "/vector-interest-settings",
      httpMethod: "GET",
    },
    [API_PUT_VECTOR]: {
      url: "/vector-interest-settings",
      httpMethod: "PUT",
    },
    [API_PATCH_VECTOR]: {
      url: "/vector-interest-settings/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_VECTOR]: {
      url: "/vector-interest-settings/${id}",
      httpMethod: "DELETE",
    },
    [API_FETCH_CONTENT_SETTINGS]: {
      url: "/content-settings",
      httpMethod: "GET",
    },
    [API_PUT_CONTENT_SETTINGS]: {
      url: "/content-settings",
      httpMethod: "PUT",
    },
    [API_PATCH_CONTENT_SETTINGS]: {
      url: "/content-settings/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_CONTENT_SETTINGS]: {
      url: "/content-settings/${id}",
      httpMethod: "DELETE",
    },
    [API_FETCH_FRIENDS_SETTINGS]: {
      url: "/friends-settings",
      httpMethod: "GET",
    },
    [API_PUT_FRIENDS_SETTINGS]: {
      url: "/friends-settings",
      httpMethod: "PUT",
    },
    [API_PATCH_FRIENDS_SETTINGS]: {
      url: "/friends-settings/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_FRIENDS_SETTINGS]: {
      url: "/friends-settings/${id}",
      httpMethod: "DELETE",
    },
    [API_FETCH_TAGS]: {
      url: "/tags",
      httpMethod: "GET",
    },
    [API_PUT_TAG]: {
      url: "/tag",
      httpMethod: "PUT",
    },
    [API_PATCH_TAG]: {
      url: "/tag/${id}",
      httpMethod: "PATCH",
    },
    [API_DELETE_TAG]: {
      url: "/tag/${id}",
      httpMethod: "DELETE",
    },
    [API_MARK_ACCOUNTS]: {
      url: "/tag/mark-accounts",
      httpMethod: "PUT",
    },
    [API_FETCH_EMULATION_SETTINGS]: {
      url: "/emulation-settings/${type}",
      httpMethod: "GET",
    },
    [API_PUT_EMULATION_SETTINGS]: {
      url: "/emulation-settings/${type}",
      httpMethod: "PUT",
    },
    [API_PATCH_EMULATION_SETTINGS]: {
      url: "/emulation-settings/${type}/${hash}",
      httpMethod: "PATCH",
    },
    [API_DELETE_EMULATION_SETTINGS]: {
      url: "/emulation-settings/${type}/${hash}",
      httpMethod: "DELETE",
    },
    [API_SYNC_EMULATION_SETTINGS]: {
      url: "/emulation-settings/${type}/sync",
      httpMethod: "POST",
    },
    [API_FETCH_ACCOUNTS_HISTORY]: {
      url: "/dashboard/accounts-status-history",
      httpMethod: "GET",
    },
    [API_FETCH_TASK_STATISTICS]: {
      url: "/dashboard/task-statistic",
      httpMethod: "GET",
    },
    [API_FETCH_CONTENT_STATISTICS]: {
      url: "/dashboard/content-statistics",
      httpMethod: "GET",
    },
    [API_FETCH_LINK_STATISTICS]: {
      url: "/dashboard/link-statistics",
      httpMethod: "GET",
    },
    [API_FETCH_MARKER_STATISTICS]: {
      url: "/dashboard/marker-statistics",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY_STATISTICS]: {
      url: "/proxies/statistics",
      httpMethod: "GET",
    },
    [API_FETCH_PROXY_PACKAGE_STATISTICS]: {
      url: "/proxy-packages/statistics",
      httpMethod: "GET",
    },
    [API_FETCH_SYNCHRONIZATION_TASK_STATISTICS]: {
      url: "/synchronization-tasks",
      httpMethod: "GET",
    },
    [API_FETCH_SYNCHRONIZATION_TASK_SCHEDULES_STATISTICS]: {
      url: "/synchronization-tasks/schedules",
      httpMethod: "GET",
    },
    [API_FETCH_PROJECT_STATUS]: {
      url: "/dashboard/project-status",
      httpMethod: "GET",
    },
    [API_TASK_QUEUE_STATUS]: {
      url: "/dashboard/task-queue-status",
      httpMethod: "GET",
    },
    [API_EMULATION_DETAILS]: {
      url: "/dashboard/emulation-details",
      httpMethod: "GET",
    },
    [API_EMULATION_SUMMARY]: {
      url: "/dashboard/emulation-summary",
      httpMethod: "GET",
    },
    [API_FETCH_ACCOUNT_HISTORIES]: {
      url: "/account-histories",
      httpMethod: "GET",
    },
    [API_EXPORT_ACCOUNT_HISTORIES]: {
      url: "/account-histories/export",
      httpMethod: "POST",
    },
    [API_FETCH_ACCOUNT_GENERATIONS]: {
      url: "/data-generation",
      httpMethod: "GET",
    },
    [API_FETCH_ACCOUNT_GENERATION_DETAILS]: {
      url: "/data-generation/${id}/items",
      httpMethod: "GET",
    },
    [API_EXPORT_ACCOUNT_GENERATION]: {
      url: "/data-generation/${id}/download",
      httpMethod: "GET",
    },
    [API_CALCULATE_ACCOUNT_GENERATION]: {
      url: "/data-generation/calculate",
      httpMethod: "POST",
    },
    [API_PUT_ACCOUNT_GENERATION]: {
      url: "/data-generation/generate",
      httpMethod: "PUT",
    },
    [API_FETCH_GENERATION_CITIES]: {
      url: "/meta/data-generation-city",
      httpMethod: "GET",
    },
    [API_FETCH_ACCOUNT_TYPES]: {
      url: "/meta/account-types",
      httpMethod: "GET",
    },
  },
};
