//Import store action constants
import {
  FETCH_ABUSE_SETTINGS,
  PATCH_ABUSE_SETTINGS,
  DELETE_ABUSE_SETTINGS,
  ACTIVATE_ABUSE_SETTINGS,
} from "@/store/actions/recommender";

//Import API endpoint constants
import {
  API_FETCH_ABUSE_SETTINGS,
  API_PATCH_ABUSE_SETTINGS,
  API_PUT_ABUSE_SETTINGS,
  API_DELETE_ABUSE_SETTINGS,
  API_ACTIVATE_ABUSE_SETTINGS,
} from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch packages list
  [FETCH_ABUSE_SETTINGS]: ({ commit, getters }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ABUSE_SETTINGS,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
          sort: getters.formattedSort,
          search: getters.formattedFilters,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_ABUSE_SETTINGS, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_ABUSE_SETTINGS]: (context, { settings, id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: id ? API_PATCH_ABUSE_SETTINGS : API_PUT_ABUSE_SETTINGS,
        data: settings,
        parts: {
          id: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_ABUSE_SETTINGS]: (context, { id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_DELETE_ABUSE_SETTINGS,
        parts: {
          id: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Update proxy package
  [ACTIVATE_ABUSE_SETTINGS]: (context, { settings }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_ACTIVATE_ABUSE_SETTINGS,
        parts: {
          id: settings.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
