import apiCall from "@/utils/api";
import {
  CALCULATE_ACCOUNT_GENERATION,
  FETCH_ACCOUNT_GENERATIONS,
  FETCH_GENERATION_CITIES,
  PUT_ACCOUNT_GENERATION,
} from "@/store/actions/account-generation";
import {
  API_CALCULATE_ACCOUNT_GENERATION,
  API_FETCH_ACCOUNT_GENERATIONS,
  API_FETCH_GENERATION_CITIES,
  API_PUT_ACCOUNT_GENERATION,
} from "@/config/api.config";

export const actions = {
  [FETCH_ACCOUNT_GENERATIONS]: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ACCOUNT_GENERATIONS,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            commit(FETCH_ACCOUNT_GENERATIONS, payload.data);
          }
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CALCULATE_ACCOUNT_GENERATION]: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_CALCULATE_ACCOUNT_GENERATION,
        data: getters.params,
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            commit(CALCULATE_ACCOUNT_GENERATION, payload.data);
          }
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_ACCOUNT_GENERATION]: ({ getters }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_PUT_ACCOUNT_GENERATION,
        data: {
          combinationGoals: getters.formattedGenerations,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_GENERATION_CITIES]: () => {
    return new Promise((resolve, reject) => {
      apiCall({ method: API_FETCH_GENERATION_CITIES })
        .then((response) => {
          let payload = response.data;
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
