//Import store action constants
import { FETCH_HISTORY } from "@/store/actions/account-history";

//Import API endpoint constants
import { API_FETCH_ACCOUNT_HISTORY } from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch packages list
  [FETCH_HISTORY]: ({ commit, getters }, { scope, gobellsId, userId }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ACCOUNT_HISTORY,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
          search: getters.formattedFilters,
        },
        parts: { scope, gobellsId, userId },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_HISTORY, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
