export const getters = {
  contentRecommenderFilters: (state) => state.contentRecommenderFilters,
  vectorFilters: (state) => state.vectorFilters,
  abuseRecommenderFilters: (state) => state.abuseRecommenderFilters,
  audienceRecommenderFilters: (state) => state.audienceRecommenderFilters,
  accountFilters: (state) => state.accountFilters,
  facebookEmulationSettingsFilters: (state) =>
    state.facebookEmulationSettingsFilters,
  accountHistoriesFilters: (state) => state.accountHistoriesFilters,
};
