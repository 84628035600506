import audience from "./audience";
import abuse from "./abuse";
import content from "./content";
import friends from "./friends";
import blacklist from "./blacklist";
import vector from "./vector";

export default {
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    audience,
    abuse,
    blacklist,
    vector,
    content,
    friends,
  },
};
