import {
  AUTH_ERROR,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  USER_SUCCESS,
  AUTH_RECOVERY_PASSWORD,
} from "@/store/actions/identity";

export const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, data) => {
    state.status = "success";
    state.accessToken = data.accessToken;
    state.refreshToken = data.refreshToken;
  },
  [AUTH_ERROR]: (state) => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: (state) => {
    state.status = "error";
    state.accessToken = false;
    state.refreshToken = false;
    state.user = null;
  },
  [USER_SUCCESS]: (state, data) => {
    state.status = "success";
    state.user = data;
  },
  [AUTH_RECOVERY_PASSWORD]: () => {},
};
