import Vue from "vue";
import Vuex from "vuex";

import SecureLS from "secure-ls";

import createPersistedState from "vuex-persistedstate";

import identity from "./modules/identity";
import proxy from "./modules/proxy";
import proxyPackage from "./modules/proxy-package";
import service from "./modules/service";
import account from "./modules/account";
import user from "./modules/user";
import socket from "./modules/socket";
import tag from "./modules/tag";
import persistentStorage from "./modules/persistentStorage";
import main from "./modules/main";

import recommender from "./modules/recommender";
import emulation from "./modules/emulation";
import projectStatistics from "./modules/project-statistics";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    main,
    identity,
    proxy,
    proxyPackage,
    service,
    account,
    user,
    recommender,
    emulation,
    socket,
    tag,
    persistentStorage,
    projectStatistics,
  },
  plugins: [
    createPersistedState({
      paths: ["identity", "persistentStorage"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
