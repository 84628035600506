//Import mutation actions constants
import { FETCH_STATISTIC } from "@/store/actions/account-statistic";

export const mutations = {
  //Store current page
  [FETCH_STATISTIC]: (state, data) => {
    state.items = data.batch;
    state.pagination.totalItems = data.total;
  },
};
