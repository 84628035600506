import moment from "moment";

export const FETCH_PROJECT_STATUS = "FETCH_PROJECT_STATUS";

import apiCall from "@/utils/api";

import { API_FETCH_PROJECT_STATUS } from "@/config/api.config";

export const actions = {
  [FETCH_PROJECT_STATUS]: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_PROJECT_STATUS,
        params: {
          limit: -1,
          search: {
            dateFrom: moment()
              .subtract(state.filters.dateFrom, "hours")
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            commit(FETCH_PROJECT_STATUS, payload.data);
          }
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
