import _ from "lodash";

export const emptyFilters = {
  id: null,
  title: null,
  target: [],
  item: null,
};

export const multipleFilters = ["target"];

export const defaultFilters = _.merge({}, emptyFilters, {});

export const state = {
  items: [], //List of services with base info only
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
  },
  sort: {
    //Ordering state, attribute & direction
    sortBy: "id",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
  emptyFilters,
  defaultFilters,
};
