//Import store action constants

import {
  FETCH_EMULATION_SETTINGS,
  PATCH_EMULATION_SETTINGS,
  DELETE_EMULATION_SETTINGS,
  SYNC_EMULATION_SETTINGS,
  SET_FILTER,
} from "../../actions/emulation-settings";

//Import API endpoint constants
import {
  API_FETCH_EMULATION_SETTINGS,
  API_PATCH_EMULATION_SETTINGS,
  API_PUT_EMULATION_SETTINGS,
  API_DELETE_EMULATION_SETTINGS,
  API_SYNC_EMULATION_SETTINGS,
} from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch packages list
  [SET_FILTER]: ({ commit }, data) => {
    commit(SET_FILTER, data);
  },
  [FETCH_EMULATION_SETTINGS]: ({ commit, getters }, { type }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_EMULATION_SETTINGS,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
          sort: getters.formattedSort,
          search: getters.formattedFilters,
        },
        parts: { type },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_EMULATION_SETTINGS, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_EMULATION_SETTINGS]: (context, { settings, hash, type }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: hash
          ? API_PATCH_EMULATION_SETTINGS
          : API_PUT_EMULATION_SETTINGS,
        data: settings,
        parts: { hash, type },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DELETE_EMULATION_SETTINGS]: (context, { hash, type }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_DELETE_EMULATION_SETTINGS,
        parts: { hash, type },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SYNC_EMULATION_SETTINGS]: (context, { type }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_SYNC_EMULATION_SETTINGS,
        parts: { type },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
