import _ from "lodash";

export const getters = {
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  filteredList: (state) => {
    const filterKeys = Object.keys(state.filters).filter(
      (e) => e !== "dateFrom"
    );
    return state.items.filter((item) => {
      return filterKeys.every((key) => {
        if (
          state.filters[key] === null ||
          (state.filters[key] instanceof Array && !state.filters[key].length)
        ) {
          return true;
        }
        return (
          item[key] === state.filters[key] ||
          state.filters[key].indexOf(item[key]) > -1
        );
      });
    });
  },
  filteredAndSortedList: (state, getter) => {
    let sortedList = getter.filteredList;
    sortedList = _.groupBy(sortedList, "status");
    sortedList = [
      ...(sortedList.error || []),
      ...(sortedList.warning || []),
      ...(sortedList.ok || []),
    ];
    sortedList = state.sort.desc ? sortedList : sortedList.reverse();
    return sortedList.slice(
      getter.offset,
      getter.offset + state.pagination.rowsPerPage
    );
  },
  title: (state) => {
    const targets = _.groupBy(state.items, "target");
    const titles = [];
    for (const key of Object.keys(targets)) {
      targets[key].unshift({ header: key.toUpperCase() });
      titles.push(...targets[key]);
    }
    return titles.map((item) => (item.header ? item : item.title));
  },
  totalCount: (state, getter) => getter.filteredList.length,
  pageCount: (state, getter) =>
    Math.ceil(getter.totalCount / state.pagination.rowsPerPage),
};
