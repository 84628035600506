import _ from "lodash";

export const emptyFilters = {
  target: [],
  initiatorType: ["system"],
  title: [],
  status: null,
  dateFrom: 6,
};

export const defaultFilters = _.merge({}, emptyFilters, {});

export const state = {
  items: [],
  pagination: {
    page: 1,
    rowsPerPage: 20,
  },
  sort: {
    sortBy: "status",
    desc: true,
  },
  filters: defaultFilters,
};
