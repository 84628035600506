//Import store action constants
import {
  FETCH_ACCOUNTS,
  EXPORT_ACCOUNTS,
  SYNC_ACCOUNTS,
  SYNC_ACCOUNT,
  FETCH_ACCOUNT,
  FETCH_DONOR_ACCOUNT,
  PATCH_DONOR,
  PATCH_ACCOUNT,
  FETCH_TAG_META,
  FETCH_REGION_META,
  FETCH_ACCOUNT_TYPES,
  FETCH_ACCOUNT_SETTINGS,
  PATCH_ACCOUNT_SETTINGS,
} from "@/store/actions/donor";

//Import API endpoint constants
import {
  API_FETCH_ACCOUNTS,
  API_EXPORT_ACCOUNTS,
  API_SYNC_ACCOUNTS,
  API_SYNC_ACCOUNT,
  API_FETCH_ACCOUNT_DETAILS,
  API_FETCH_DONOR_ACCOUNT,
  API_PATCH_DONOR,
  API_PATCH_ACCOUNT,
  API_FETCH_TAG_META,
  API_FETCH_REGION_META,
  API_FETCH_ACCOUNT_TYPES,
  API_FETCH_ACCOUNT_SETTINGS,
  API_PATCH_ACCOUNT_SETTINGS,
} from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch packages list
  [FETCH_ACCOUNTS]: ({ commit, getters }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ACCOUNTS,
        params: {
          scope: ["no-scope"],
          limit: getters.itemsPerPage,
          offset: getters.offset,
          sort: getters.formattedSort,
          search: getters.formattedFilters,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_ACCOUNTS, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch packages list
  [EXPORT_ACCOUNTS]: ({ getters }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_EXPORT_ACCOUNTS,
        data: {
          search: getters.formattedFilters,
          scope: ["no-scope"],
          sort: getters.formattedSort,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch packages list
  [SYNC_ACCOUNTS]: (context, { scope }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_SYNC_ACCOUNTS,
        parts: { scope },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch packages list
  [SYNC_ACCOUNT]: (context, { scope, gobellsId, userId }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_SYNC_ACCOUNT,
        parts: { scope, gobellsId, userId },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch packages list
  [FETCH_DONOR_ACCOUNT]: (context, { gobellsId }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_DONOR_ACCOUNT,
        parts: { gobellsId },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_ACCOUNT]: (context, { type, gobellsId, userId }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ACCOUNT_DETAILS,
        parts: { gobellsId, type, userId },
      })
        .then((response) => {
          let payload = response.data;
          // if (payload.status && payload.data) {
          //   //Save package list to store via mutation
          //   commit(FETCH_ACCOUNTS, payload.data);
          // }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_DONOR]: ({ dispatch }, { donor }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PATCH_DONOR,
        data: donor,
        parts: {
          donorId: donor.donorId,
        },
      })
        .then((response) => {
          let payload = response.data;
          dispatch(FETCH_ACCOUNTS);
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_ACCOUNT]: ({ dispatch }, { account, gobellsId, scope, userId }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PATCH_ACCOUNT,
        data: account,
        parts: { gobellsId, scope, userId },
      })
        .then((response) => {
          let payload = response.data;
          dispatch(FETCH_ACCOUNTS);
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Meta data actions
  [FETCH_TAG_META]: (context, data) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_TAG_META,
        params: data,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Meta data actions
  [FETCH_REGION_META]: (context, data) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_REGION_META,
        params: data,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_ACCOUNT_TYPES]: (context, data) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_ACCOUNT_TYPES,
        params: data,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_ACCOUNT_SETTINGS]: (context, { gobellsId, type, userId }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_FETCH_ACCOUNT_SETTINGS,
        parts: { gobellsId, type, userId },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_ACCOUNT_SETTINGS]: (
    context,
    { settings, gobellsId, type, userId }
  ) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PATCH_ACCOUNT_SETTINGS,
        data: { settings },
        parts: { gobellsId, type, userId },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
