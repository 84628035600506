export const FETCH_ACCOUNTS_HISTORY_STAT = "FETCH_ACCOUNTS_HISTORY_STAT";
export const FETCH_TASK_STAT = "FETCH_TASK_STAT";
export const FETCH_PROXY_STAT = "FETCH_PROXY_STAT";
export const FETCH_PROXY_PACKAGE_STAT = "FETCH_PROXY_PACKAGE_STAT";
export const FETCH_CONTENT_STAT = "FETCH_CONTENT_STAT";
export const FETCH_CONTENT_LINK_STAT = "FETCH_CONTENT_LINK_STAT";
export const FETCH_CONTENT_MARKER_STAT = "FETCH_CONTENT_MARKER_STAT";
export const FETCH_SYNCHRONIZATION_TASK_STAT =
  "FETCH_SYNCHRONIZATION_TASK_STAT";
export const FETCH_SYNCHRONIZATION_SCHEDULE = "FETCH_SYNCHRONIZATION_SCHEDULE";
export const FETCH_TASK_QUEUE_STATUS = "FETCH_TASK_QUEUE_STATUS";
export const FETCH_EMULATION_SUMMARY = "FETCH_EMULATION_SUMMARY";
export const FETCH_EMULATION_DETAILS = "FETCH_EMULATION_DETAILS";

import apiCall from "@/utils/api";

import {
  API_FETCH_ACCOUNTS_HISTORY,
  API_FETCH_TASK_STATISTICS,
  API_FETCH_PROXY_STATISTICS,
  API_FETCH_PROXY_PACKAGE_STATISTICS,
  API_FETCH_CONTENT_STATISTICS,
  API_FETCH_LINK_STATISTICS,
  API_FETCH_MARKER_STATISTICS,
  API_FETCH_SYNCHRONIZATION_TASK_STATISTICS,
  API_FETCH_SYNCHRONIZATION_TASK_SCHEDULES_STATISTICS,
  API_TASK_QUEUE_STATUS,
  API_EMULATION_SUMMARY,
  API_EMULATION_DETAILS,
} from "@/config/api.config";

export default {
  [FETCH_ACCOUNTS_HISTORY_STAT]: (
    context,
    { reportDateFrom, reportDateTo }
  ) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_ACCOUNTS_HISTORY,
        params: {
          additionalGroupBy: ["target", "stage", "type"],
          search: {
            reportDateFrom,
            reportDateTo,
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_TASK_STAT]: (context, { reportDateFrom, reportDateTo }) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_TASK_STATISTICS,
        params: {
          additionalGroupBy: ["target", "taskCommand", "stage", "type"],
          search: {
            reportDateFrom,
            reportDateTo,
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PROXY_STAT]: (context, { reportDateFrom, reportDateTo }) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_PROXY_STATISTICS,
        params: {
          search: {
            reportDateFrom,
            reportDateTo,
            onlyWithErrors: 1,
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PROXY_PACKAGE_STAT]: (context, { reportDateFrom, reportDateTo }) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_PROXY_PACKAGE_STATISTICS,
        params: {
          search: {
            reportDateFrom,
            reportDateTo,
            onlyWithErrors: 1,
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CONTENT_STAT]: () => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_CONTENT_STATISTICS,
        params: {
          limit: -1,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CONTENT_LINK_STAT]: () => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_LINK_STATISTICS,
        params: {
          limit: -1,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CONTENT_MARKER_STAT]: () => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_MARKER_STATISTICS,
        params: {
          limit: -1,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_SYNCHRONIZATION_TASK_STAT]: (
    context,
    { reportDateFrom, reportDateTo, scheduleId, target }
  ) => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_SYNCHRONIZATION_TASK_STATISTICS,
        params: {
          limit: -1,
          search: {
            reportDateFrom,
            reportDateTo,
            scheduleId,
            target,
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_SYNCHRONIZATION_SCHEDULE]: () => {
    return new Promise((resolve, reject) => {
      //Call auth/login API method
      apiCall({
        method: API_FETCH_SYNCHRONIZATION_TASK_SCHEDULES_STATISTICS,
        params: {
          limit: -1,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Commit changes to store
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_TASK_QUEUE_STATUS]: (context, { dateFrom, target }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_TASK_QUEUE_STATUS,
        params: {
          limit: -1,
          search: {
            dateFrom,
            target,
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_EMULATION_SUMMARY]: (context, { dateFrom, target }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_EMULATION_SUMMARY,
        params: {
          search: {
            dateFrom,
            target,
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_EMULATION_DETAILS]: (context, { dateFrom, target, requestType }) => {
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_EMULATION_DETAILS,
        params: {
          search: {
            dateFrom,
            target,
            requestType,
            status: "error",
          },
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            resolve(payload.data);
          } else {
            reject(payload);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
