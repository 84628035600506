export const FETCH_AUDIENCE_SETTINGS = "FETCH_AUDIENCE_SETTINGS";
export const PATCH_AUDIENCE_SETTINGS = "PATCH_AUDIENCE_SETTINGS";
export const DELETE_AUDIENCE_SETTINGS = "DELETE_AUDIENCE_SETTINGS";
export const ACTIVATE_AUDIENCE_SETTINGS = "ACTIVATE_AUDIENCE_SETTINGS";

export const FETCH_ABUSE_SETTINGS = "FETCH_ABUSE_SETTINGS";
export const PATCH_ABUSE_SETTINGS = "PATCH_ABUSE_SETTINGS";
export const DELETE_ABUSE_SETTINGS = "DELETE_ABUSE_SETTINGS";
export const ACTIVATE_ABUSE_SETTINGS = "ACTIVATE_ABUSE_SETTINGS";

export const FETCH_BLACKLIST = "FETCH_BLACKLIST";
export const PATCH_BLACKLIST = "PATCH_BLACKLIST";
export const DELETE_BLACKLIST = "DELETE_BLACKLIST";

export const FETCH_BLACKLIST_ITEMS = "FETCH_BLACKLIST_ITEMS";
export const PATCH_BLACKLIST_ITEMS = "PATCH_BLACKLIST_ITEMS";
export const PUT_BLACKLIST_ITEMS = "PUT_BLACKLIST_ITEMS";
export const DELETE_BLACKLIST_ITEMS = "DELETE_BLACKLIST_ITEMS";

export const FETCH_VECTORS = "FETCH_VECTORS";
export const PATCH_VECTOR = "PATCH_VECTOR";
export const PUT_VECTOR = "PUT_VECTOR";
export const DELETE_VECTOR = "DELETE_VECTOR";

export const FETCH_CONTENT_SETTINGS = "FETCH_CONTENT_SETTINGS";
export const PATCH_CONTENT_SETTINGS = "PATCH_CONTENT_SETTINGS";
export const DELETE_CONTENT_SETTINGS = "DELETE_CONTENT_SETTINGS";

export const FETCH_FRIENDS_SETTINGS = "FETCH_FRIENDS_SETTINGS";
export const PATCH_FRIENDS_SETTINGS = "PATCH_FRIENDS_SETTINGS";
export const DELETE_FRIENDS_SETTINGS = "DELETE_FRIENDS_SETTINGS";

export const SET_FILTER = "SET_FILTER";
