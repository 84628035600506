import Vue from "vue";
import store from "@/store";
import router from "@/router";

import _ from "lodash";

import { apiRoutes } from "@/config/api.config";
import { AUTH_LOGOUT } from "@/store/actions/identity";

const apiCall = ({ method, data, params, parts, headers = {} }) =>
  new Promise((resolve, reject) => {
    try {
      let action = _.get(apiRoutes.methods, [method]);

      let url = _.template(apiRoutes.host + action.url);
      let token = store.getters["token"];
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      Vue.axios({
        url: url(parts),
        method: action.httpMethod,
        data,
        params,
        headers,
      }).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            store.commit(AUTH_LOGOUT);
            router.go({ name: "Dashboard" });
          } else {
            resolve(error.response);
          }
        }
      );
    } catch (err) {
      reject(new Error(err));
    }
  });

export default apiCall;
