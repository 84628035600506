import _ from "lodash";

import {
  SCOPE_FACEBOOK,
  SCOPE_YOUTUBE,
  SCOPE_TWITTER,
  SCOPE_INSTAGRAM,
} from "@/store/modules/service/getters";

export const getters = {
  //Base getters
  items: (state) => state.items,
  itemsDetails: (state) => state.itemDetails,
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,
  sort: (state) => state.sort,

  //Formatters
  formattedSort: (state) =>
    state.sort.sortBy ? (state.sort.desc ? "-" : "") + state.sort.sortBy : null,
  formattedFilters: (state) => {
    let filters = {};
    _.forIn(state.filters, function (value, key) {
      if (value !== null && (!(value instanceof Array) || value.length)) {
        filters[key] = value;
      }
    });
    return filters;
  },

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,
  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),

  availableScopes: () => [
    SCOPE_FACEBOOK,
    SCOPE_YOUTUBE,
    SCOPE_TWITTER,
    SCOPE_INSTAGRAM,
  ],
  recommenders: function () {
    return {
      [SCOPE_FACEBOOK]: [
        "content",
        "friend",
        "follower",
        "subscription",
        "invite",
      ],
      [SCOPE_YOUTUBE]: ["subscription"],
      [SCOPE_TWITTER]: ["content", "subscription"],
      [SCOPE_INSTAGRAM]: ["content", "subscription"],
    };
  },
  availableRecommenders: (state, getter) => (scope) => {
    const recommenders = _.get(getter.recommenders, scope);
    return recommenders ? recommenders : [];
  },
};
