import _ from "lodash";

import {
  SCOPE_INSTAGRAM,
  SCOPE_FACEBOOK,
  SCOPE_YOUTUBE,
  SCOPE_TWITTER,
} from "@/store/modules/service/getters";

export const getters = {
  //Base getters
  items: (state) => state.items,
  itemsDetails: (state) => state.itemDetails,
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,
  sort: (state) => state.sort,

  //Formatters
  formattedSort: (state) =>
    state.sort.sortBy ? (state.sort.desc ? "-" : "") + state.sort.sortBy : null,
  formattedFilters: (state) => {
    let filters = {};
    _.forIn(state.filters, function (value, key) {
      if (value !== null && (!(value instanceof Array) || value.length)) {
        filters[key] = value;
      }
    });
    return filters;
  },

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,
  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),
  availableScopes: () => [
    SCOPE_FACEBOOK,
    SCOPE_INSTAGRAM,
    SCOPE_YOUTUBE,
    SCOPE_TWITTER,
  ],
  entity: function () {
    return {
      [SCOPE_FACEBOOK]: ["user", "page", "post", "comment", "group", "ads"],
      [SCOPE_INSTAGRAM]: ["user", "post", "comment", "reply"],
      [SCOPE_TWITTER]: ["user", "tweet"],
      [SCOPE_YOUTUBE]: ["video", "channel"],
    };
  },
  period: function () {
    return ["day"];
  },
  availableEntities: (state, getter) => (scope) => {
    const actions = _.get(getter.entity, scope);
    return actions ? actions : [];
  },
  availableSegments: () => [
    "type",
    "ageGroup",
    "language",
    "stage",
    "tagId",
    "gender",
  ],

  availableStatuses: () => ["active", "inactive"],

  statusColor: () => {
    return {
      active: "green",
      inactive: "light-blue",
    };
  },
  getColorByStatus: (state, getter) => ({ status, defaultValue }) => {
    return _.get(getter.statusColor, status, defaultValue);
  },
};
