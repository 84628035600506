import guest from "./middleware/guest";
import user from "./middleware/user";
import admin from "./middleware/admin";

export default [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("@/views/main/Dashboard.vue"),
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/sign-in",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "blank",
      middleware: [guest],
    },
  },
  {
    path: "/password-recovery",
    name: "PasswordRecovery",
    component: () => import("@/views/auth/Password.vue"),
    meta: {
      layout: "blank",
      middleware: [guest],
    },
  },
  {
    path: "/project-statistics",
    name: "ProjectStatistics",
    component: () => import("@/views/project-statistics/ProjectStatistics.vue"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/profile-management",
    name: "ProfileList",
    component: () => import("@/views/profile-management/ProfileList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [admin],
    },
  },
  {
    path: "/source-management",
    name: "SourceList",
    component: () => import("@/views/source-management/SourceList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [admin],
    },
  },
  {
    path: "/proxy-packages",
    name: "ProxyPackageList",
    component: () => import("@/views/proxy-packages/ProxyPackagesList.vue"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/proxies",
    name: "ProxyList",
    component: () => import("@/views/proxy/ProxyList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/accounts",
    name: "AccountList",
    component: () => import("@/views/account/AccountList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/account/:gobellsId/:scope/:userId",
    name: "AccountPage",
    component: () => import("@/views/account/AccountPage"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/audience-recommender",
    name: "AudienceRecommender",
    component: () =>
      import("@/views/recommender/audience/AudienceRecommenderList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/abuse-recommender",
    name: "AbuseRecommender",
    component: () => import("@/views/recommender/abuse/AbuseRecommenderList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/content-recommender",
    name: "ContentRecommender",
    component: () =>
      import("@/views/recommender/content/ContentRecommenderList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/friends-recommender",
    name: "FriendsRecommender",
    component: () =>
      import("@/views/recommender/friends/FriendsRecommenderList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/blacklist",
    name: "Blacklist",
    component: () => import("@/views/recommender/blacklist/BlacklistList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/vector-interest",
    name: "VectorInterest",
    component: () => import("@/views/recommender/vector/VectorInterestList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/tags",
    name: "TagList",
    component: () => import("@/views/tag/TagList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/emulation-settings/:target",
    name: "EmulationSettingsList",
    component: () => import("@/views/emulation/EmulationSettingsList"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/account-histories",
    name: "AccountHistories",
    component: () => import("@/views/account-histories/AccountHistories"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/account-generation",
    name: "AccountGeneration",
    component: () => import("@/views/account-generation/AccountGeneration"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
  {
    path: "/account-generation-details/:generationId",
    name: "AccountGenerationDetails",
    component: () =>
      import("@/views/account-generation-details/AccountGenerationDetails"),
    props: true,
    meta: {
      layout: "default",
      middleware: [user],
    },
  },
];
