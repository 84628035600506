//Import mutation actions constants
import {
  FETCH_FRIENDS_SETTINGS,
  SET_FILTER,
} from "@/store/actions/recommender";

export const mutations = {
  //Store current page
  [FETCH_FRIENDS_SETTINGS]: (state, data) => {
    state.items = data.batch;
    state.pagination.totalItems = data.total;
  },
  //Store current page
  [SET_FILTER]: (state, filters) => {
    state.pagination.page = 1;
    state.filters = filters;
  },
};
