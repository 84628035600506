import Vue from "vue";

import App from "./App.vue";

import vuetify from "./plugins/vuetify";

import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";
import VuePapaParse from "vue-papa-parse";

import VueSocketIO from "vue-socket.io";

import Notifications from "vue-notification";

import FlagIcon from "vue-flag-icon";
import router from "./router";
import store from "./store";
import i18n from "./i18n/index";

import { VueMaskDirective } from "v-mask";

import Qs from "qs";

// Format nested params correctly
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    // Qs is not included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(FlagIcon);
Vue.use(Notifications);
Vue.use(VuePapaParse);

Vue.directive("mask", VueMaskDirective);

Vue.use(
  new VueSocketIO({
    debug: process.env.VUE_APP_DEBUG,
    connection: process.env.VUE_APP_SOCKET_URL,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

window.history.pushState({}, "", location.href);
