<template>
  <v-list-item-group v-model="selectedItem" color="primary">
    <router-link class="text-decoration-none" :to="item.route">
      <v-list-item :value="item.route.name" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </router-link>
  </v-list-item-group>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      selectedItem: null,
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.selectedItem = this.$route.name;
      },
      deep: true,
    },
  },
  mounted() {
    this.selectedItem = this.$route.name;
  },
};
</script>
