import _ from "lodash";

export const getters = {
  params: (state) => state.params,
  items: (state) => state.items,
  pagination: (state) => state.pagination,
  generation: (state) => state.generation,

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,
  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),
  statusColor: () => {
    return {
      completed: "green",
      processing: "indigo",
      failed: "red",
      new: "grey",
      ready: "light-blue",
    };
  },
  getColorByStatus: (state, getter) => ({ status, defaultValue }) => {
    return _.get(getter.statusColor, status, defaultValue);
  },
  formattedGenerations: (state, getters) => {
    return _.filter(
      getters.generation,
      (item) => item.numGoal > 0
    ).map(({ id, numGoal }) => ({ id, value: numGoal }));
  },
};
