<template>
  <v-app>
    <v-main class="grey lighten-4">
      <v-container class="spacing-playground pa-7" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <notifications group="messages" />
  </v-app>
</template>

<script>
export default {
  name: "Blank",
};
</script>
