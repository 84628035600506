import _ from "lodash";

export const emptyFilters = {
  target: null,
  donorId: null,
  gobellsId: null,
  userId: null,
  type: null,
  gender: null,
  age: null,
  language: null,
  stage: null,
  errorCode: null,
  status: null,
  friendsCount: null,
  display: null,
  statusChangedRange: null,
  statusChangedAt: null,
  statusChangedFrom: null,
  statusChangedTo: null,
  lastActivity: null,
  tags: null,
  groups: null,
  fullname: null,
  domain: null,
  birthday: null,
  hasProxy: null,
  proxy: null,
  query: null,
  proxyPackages: null,
  regionId: null,
  hasLinkedAccount: null,
  updatedAt: null,
};

export const multipleFilters = [
  "target",
  "type",
  "age",
  "language",
  "stage",
  "status",
  "tags",
  "gender",
  "proxyPackages",
  "regionId",
  "statusChangedRange",
  "scope",
];

export const defaultFilters = _.merge({}, emptyFilters, {
  stage: [4],
  status: ["active"],
});

export const state = {
  items: [], //List of donors with base info only
  info: {},
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
  },
  sort: {
    //Ordering state, attribute & direction
    sortBy: "donorId",
    desc: true,
  },
  filters: defaultFilters,
  emptyFilters,
};
