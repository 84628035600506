//Import store action constants
import {
  FETCH_PROXIES,
  FETCH_PROXY,
  SAVE_PROXY,
  SYNC_PROXY,
  SYNC_PROXIES,
} from "@/store/actions/proxy";

//Import API endpoint constants
import {
  API_FETCH_PROXIES,
  API_FETCH_PROXY,
  API_PATCH_PROXY,
  API_SYNC_PROXY,
  API_SYNC_PROXIES,
} from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch proxies list
  [FETCH_PROXIES]: ({ commit, getters }) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_PROXIES,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
          sort: getters.formattedSort,
          search: getters.formattedFilters,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save proxy list to store via mutation
            commit(FETCH_PROXIES, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch proxy details
  [FETCH_PROXY]: ({ commit }, { item }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_FETCH_PROXY,
        parts: {
          id: item.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Store proxy details to the state
            commit(FETCH_PROXY, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Update proxy
  [SAVE_PROXY]: (context, { proxy }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PATCH_PROXY,
        data: proxy,
        parts: {
          id: proxy.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Update proxy package
  [SYNC_PROXY]: (context, { proxy }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_SYNC_PROXY,
        parts: {
          id: proxy.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Update proxy package
  [SYNC_PROXIES]: () => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_SYNC_PROXIES,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
