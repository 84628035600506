//Import mutation actions constants
import { FETCH_HISTORY } from "@/store/actions/account-history";

export const mutations = {
  //Store current page
  [FETCH_HISTORY]: (state, data) => {
    state.items = data.batch;
    state.pagination.totalItems = data.total;
  },
};
