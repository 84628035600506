import _ from "lodash";

export const defaultContentRecommenderFilters = [
  "settingsTitle",
  "type",
  "stage",
  "gender",
];
export const availableContentRecommenderFilters = [
  "settingsTitle",
  "type",
  "gender",
  "ageGroup",
  "language",
  "stage",
  "tagId",
];

export const defaultFriendsRecommenderFilters = [
  "settingsTitle",
  "type",
  "stage",
  "gender",
];
export const availableFriendsRecommenderFilters = [
  "settingsTitle",
  "type",
  "gender",
  "ageGroup",
  "language",
  "stage",
  "tagId",
];

export const defaultAudienceRecommenderFilters = [
  "tagId",
  "type",
  "stage",
  "gender",
];
export const availableAudienceRecommenderFilters = [
  "type",
  "ageGroup",
  "language",
  "stage",
  "tagId",
  "gender",
];

export const defaultAbuseRecommenderFilters = [
  "tagId",
  "type",
  "stage",
  "gender",
];
export const availableAbuseRecommenderFilters = [
  "type",
  "ageGroup",
  "language",
  "stage",
  "tagId",
  "gender",
];

export const defaultEmulationSettingsFilters = ["type", "stage"];
export const availableEmulationSettingsFilters = ["type", "stage"];

export const defaultVectorFilters = ["tagId"];
export const availableVectorFilters = ["contentTagIds", "tagId"];

export const defaultAccountFilters = [
  "query",
  "status",
  "stage",
  "target",
  "type",
];
export const availableAccountFilters = [
  "target",
  "donorId",
  "userId",
  "type",
  "gender",
  "age",
  "language",
  "stage",
  "errorCode",
  "status",
  "friendsCount",
  "display",
  "statusChangedRange",
  "tags",
  "fullname",
  "domain",
  "birthday",
  "hasProxy",
  "proxy",
  "query",
  "proxyPackages",
  "regionId",
  "hasLinkedAccount",
  "updatedAt",
];

export const defaultAccountHistoriesFilters = [];

export const availableAccountHistoriesFilters = [
  "scope",
  "reportChangedRange",
  "userId",
  "attribute",
  "oldValue",
  "newValue",
  "currentDomain",
  "currentStatus",
  "currentStage",
  "currentType",
];

export const state = {
  contentRecommenderFilters: _.merge([], defaultContentRecommenderFilters),
  friendsRecommenderFilters: _.merge([], defaultFriendsRecommenderFilters),
  vectorFilters: _.merge([], defaultVectorFilters),
  abuseRecommenderFilters: _.merge([], defaultAbuseRecommenderFilters),
  audienceRecommenderFilters: _.merge([], defaultAudienceRecommenderFilters),
  accountFilters: _.merge([], defaultAccountFilters),
  facebookEmulationSettingsFilters: _.merge(
    [],
    defaultEmulationSettingsFilters
  ),
  accountHistoriesFilters: _.merge([], defaultAccountHistoriesFilters),
};
