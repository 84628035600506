export const FETCH_ACCOUNT_GENERATIONS = "FETCH_ACCOUNT_GENERATIONS";

export const EXPORT_ACCOUNT_GENERATION = "EXPORT_ACCOUNT_GENERATION";

export const CALCULATE_ACCOUNT_GENERATION = "CALCULATE_ACCOUNT_GENERATION";
export const PUT_ACCOUNT_GENERATION = "PUT_ACCOUNT_GENERATION";

export const SET_GENERATION_PARAMS = "SET_GENERATION_PARAMS";
export const RESET_GENERATION_PARAMS = "RESET_GENERATION_PARAMS";

export const FETCH_GENERATION_CITIES = "FETCH_GENERATION_CITIES";
