import donor from "./donor";
import history from "./history";
import statistic from "./statistic";
import histories from "./histories";
import generation from "./generation";
import generationDetails from "./generation-details";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    donor,
    history,
    statistic,
    histories,
    generation,
    generationDetails,
  },
};
