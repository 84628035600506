export const countries = [
  {
    value: "CN",
    title: "China",
  },
  {
    value: "IN",
    title: "India",
  },
  {
    value: "US",
    title: "United States",
  },
  {
    value: "ID",
    title: "Indonesia",
  },
  {
    value: "BR",
    title: "Brazil",
  },
  {
    value: "PK",
    title: "Pakistan",
  },
  {
    value: "NG",
    title: "Nigeria",
  },
  {
    value: "BD",
    title: "Bangladesh",
  },
  {
    value: "RU",
    title: "Russia",
  },
  {
    value: "MX",
    title: "Mexico",
  },
  {
    value: "JP",
    title: "Japan",
  },
  {
    value: "ET",
    title: "Ethiopia",
  },
  {
    value: "PH",
    title: "Philippines",
  },
  {
    value: "EG",
    title: "Egypt",
  },
  {
    value: "VN",
    title: "Vietnam",
  },
  {
    value: "DE",
    title: "Germany",
  },
  {
    value: "CD",
    title: "Democratic Republic of the Congo",
  },
  {
    value: "IR",
    title: "Iran",
  },
  {
    value: "TR",
    title: "Turkey",
  },
  {
    value: "TH",
    title: "Thailand",
  },
  {
    value: "GB",
    title: "United Kingdom",
  },
  {
    value: "FR",
    title: "France",
  },
  {
    value: "IT",
    title: "Italy",
  },
  {
    value: "TZ",
    title: "Tanzania",
  },
  {
    value: "ZA",
    title: "South Africa",
  },
  {
    value: "MM",
    title: "Myanmar",
  },
  {
    value: "KR",
    title: "South Korea",
  },
  {
    value: "CO",
    title: "Colombia",
  },
  {
    value: "KE",
    title: "Kenya",
  },
  {
    value: "ES",
    title: "Spain",
  },
  {
    value: "AR",
    title: "Argentina",
  },
  {
    value: "UA",
    title: "Ukraine",
  },
  {
    value: "UG",
    title: "Uganda",
  },
  {
    value: "DZ",
    title: "Algeria",
  },
  {
    value: "SD",
    title: "Sudan",
  },
  {
    value: "IQ",
    title: "Iraq",
  },
  {
    value: "PL",
    title: "Poland",
  },
  {
    value: "CA",
    title: "Canada",
  },
  {
    value: "MA",
    title: "Morocco",
  },
  {
    value: "AF",
    title: "Afghanistan",
  },
  {
    value: "SA",
    title: "Saudi Arabia",
  },
  {
    value: "PE",
    title: "Peru",
  },
  {
    value: "VE",
    title: "Venezuela",
  },
  {
    value: "UZ",
    title: "Uzbekistan",
  },
  {
    value: "MY",
    title: "Malaysia",
  },
  {
    value: "AO",
    title: "Angola",
  },
  {
    value: "MZ",
    title: "Mozambique",
  },
  {
    value: "NP",
    title: "Nepal",
  },
  {
    value: "GH",
    title: "Ghana",
  },
  {
    value: "YE",
    title: "Yemen",
  },
  {
    value: "MG",
    title: "Madagascar",
  },
  {
    value: "KP",
    title: "North Korea",
  },
  {
    value: "AU",
    title: "Australia",
  },
  {
    value: "CI",
    title: "Ivory Coast",
  },
  {
    value: "CM",
    title: "Cameroon",
  },
  {
    value: "TW",
    title: "Taiwan",
  },
  {
    value: "NE",
    title: "Niger",
  },
  {
    value: "LK",
    title: "Sri Lanka",
  },
  {
    value: "RO",
    title: "Romania",
  },
  {
    value: "BF",
    title: "Burkina Faso",
  },
  {
    value: "MW",
    title: "Malawi",
  },
  {
    value: "ML",
    title: "Mali",
  },
  {
    value: "SY",
    title: "Syria",
  },
  {
    value: "KZ",
    title: "Kazakhstan",
  },
  {
    value: "CL",
    title: "Chile",
  },
  {
    value: "ZM",
    title: "Zambia",
  },
  {
    value: "NL",
    title: "Netherlands",
  },
  {
    value: "GT",
    title: "Guatemala",
  },
  {
    value: "EC",
    title: "Ecuador",
  },
  {
    value: "ZW",
    title: "Zimbabwe",
  },
  {
    value: "KH",
    title: "Cambodia",
  },
  {
    value: "SN",
    title: "Senegal",
  },
  {
    value: "TD",
    title: "Chad",
  },
  {
    value: "SO",
    title: "Somalia",
  },
  {
    value: "GN",
    title: "Guinea",
  },
  {
    value: "SD",
    title: "Sudan",
  },
  {
    value: "RW",
    title: "Rwanda",
  },
  {
    value: "TN",
    title: "Tunisia",
  },
  {
    value: "CU",
    title: "Cuba",
  },
  {
    value: "BE",
    title: "Belgium",
  },
  {
    value: "BJ",
    title: "Benin",
  },
  {
    value: "GR",
    title: "Greece",
  },
  {
    value: "BO",
    title: "Bolivia",
  },
  {
    value: "HT",
    title: "Haiti",
  },
  {
    value: "BI",
    title: "Burundi",
  },
  {
    value: "DO",
    title: "Dominican Republic",
  },
  {
    value: "CZ",
    title: "Czech Republic",
  },
  {
    value: "PT",
    title: "Portugal",
  },
  {
    value: "SE",
    title: "Sweden",
  },
  {
    value: "AZ",
    title: "Azerbaijan",
  },
  {
    value: "HU",
    title: "Hungary",
  },
  {
    value: "JO",
    title: "Jordan",
  },
  {
    value: "BY",
    title: "Belarus",
  },
  {
    value: "AE",
    title: "United Arab Emirates",
  },
  {
    value: "HN",
    title: "Honduras",
  },
  {
    value: "TJ",
    title: "Tajikistan",
  },
  {
    value: "RS",
    title: "Serbia",
  },
  {
    value: "AT",
    title: "Austria",
  },
  {
    value: "CH",
    title: "Switzerland",
  },
  {
    value: "IL",
    title: "Israel",
  },
  {
    value: "PG",
    title: "Papua New Guinea",
  },
  {
    value: "TG",
    title: "Togo",
  },
  {
    value: "SL",
    title: "Sierra Leone",
  },
  {
    value: "HK",
    title: "Hong Kong",
  },
  {
    value: "BG",
    title: "Bulgaria",
  },
  {
    value: "LA",
    title: "Laos",
  },
  {
    value: "PY",
    title: "Paraguay",
  },
  {
    value: "SV",
    title: "El Salvador",
  },
  {
    value: "LY",
    title: "Libya",
  },
  {
    value: "NI",
    title: "Nicaragua",
  },
  {
    value: "LB",
    title: "Lebanon",
  },
  {
    value: "KG",
    title: "Kyrgyzstan",
  },
  {
    value: "TM",
    title: "Turkmenistan",
  },
  {
    value: "DK",
    title: "Denmark",
  },
  {
    value: "SG",
    title: "Singapore",
  },
  {
    value: "FI",
    title: "Finland",
  },
  {
    value: "SK",
    title: "Slovakia",
  },
  {
    value: "NO",
    title: "Norway",
  },
  {
    value: "CG",
    title: "Congo",
  },
  {
    value: "ER",
    title: "Eritrea",
  },
  {
    value: "PS",
    title: "Palestine",
  },
  {
    value: "CR",
    title: "Costa Rica",
  },
  {
    value: "IE",
    title: "Ireland",
  },
  {
    value: "LR",
    title: "Liberia",
  },
  {
    value: "NZ",
    title: "New Zealand",
  },
  {
    value: "CF",
    title: "Central African Republic",
  },
  {
    value: "OM",
    title: "Oman",
  },
  {
    value: "MR",
    title: "Mauritania",
  },
  {
    value: "HR",
    title: "Croatia",
  },
  {
    value: "KW",
    title: "Kuwait",
  },
  {
    value: "PA",
    title: "Panama",
  },
  {
    value: "MD",
    title: "Moldova",
  },
  {
    value: "GE",
    title: "Georgia",
  },
  {
    value: "PR",
    title: "Puerto Rico",
  },
  {
    value: "BA",
    title: "Bosnia and Herzegovina",
  },
  {
    value: "UY",
    title: "Uruguay",
  },
  {
    value: "MN",
    title: "Mongolia",
  },
  {
    value: "AM",
    title: "Armenia",
  },
  {
    value: "AL",
    title: "Albania",
  },
  {
    value: "JM",
    title: "Jamaica",
  },
  {
    value: "LT",
    title: "Lithuania",
  },
  {
    value: "QA",
    title: "Qatar",
  },
  {
    value: "NA",
    title: "Namibia",
  },
  {
    value: "BW",
    title: "Botswana",
  },
  {
    value: "LS",
    title: "Lesotho",
  },
  {
    value: "GM",
    title: "The Gambia",
  },
  {
    value: "MK",
    title: "Republic of Macedonia",
  },
  {
    value: "SI",
    title: "Slovenia",
  },
  {
    value: "GA",
    title: "Gabon",
  },
  {
    value: "LV",
    title: "Latvia",
  },
  {
    value: "GW",
    title: "Guinea-Bissau",
  },
  {
    value: "BH",
    title: "Bahrain",
  },
  {
    value: "TT",
    title: "Trinidad and Tobago",
  },
  {
    value: "SZ",
    title: "Swaziland",
  },
  {
    value: "EE",
    title: "Estonia",
  },
  {
    value: "TL",
    title: "East Timor",
  },
  {
    value: "GQ",
    title: "Equatorial Guinea",
  },
  {
    value: "MU",
    title: "Mauritius",
  },
  {
    value: "CY",
    title: "Cyprus",
  },
  {
    value: "DJ",
    title: "Djibouti",
  },
  {
    value: "FJ",
    title: "Fiji",
  },
  {
    value: "RE",
    title: "Réunion",
  },
  {
    value: "KM",
    title: "Comoros",
  },
  {
    value: "BT",
    title: "Bhutan",
  },
  {
    value: "GY",
    title: "Guyana",
  },
  {
    value: "ME",
    title: "Montenegro",
  },
  {
    value: "MO",
    title: "Macau",
  },
  {
    value: "SB",
    title: "Solomon Islands",
  },
  {
    value: "LU",
    title: "Luxembourg",
  },
  {
    value: "SR",
    title: "Suriname",
  },
  {
    value: "EH",
    title: "Western Sahara",
  },
  {
    value: "CV",
    title: "Cape Verde",
  },
  {
    value: "GP",
    title: "Guadeloupe",
  },
  {
    value: "MV",
    title: "Maldives",
  },
  {
    value: "MT",
    title: "Malta",
  },
  {
    value: "BN",
    title: "Brunei",
  },
  {
    value: "BS",
    title: "Bahamas",
  },
  {
    value: "MQ",
    title: "Martinique",
  },
  {
    value: "BZ",
    title: "Belize",
  },
  {
    value: "IS",
    title: "Iceland",
  },
  {
    value: "BB",
    title: "Barbados",
  },
  {
    value: "PF",
    title: "French Polynesia",
  },
  {
    value: "GF",
    title: "French Guiana",
  },
  {
    value: "NC",
    title: "New Caledonia",
  },
  {
    value: "VU",
    title: "Vanuatu",
  },
  {
    value: "YT",
    title: "Mayotte",
  },
  {
    value: "ST",
    title: "Sao Tome and Principe",
  },
  {
    value: "WS",
    title: "Samoa",
  },
  {
    value: "LC",
    title: "Saint Lucia",
  },
  {
    value: "JE",
    title: "Guernsey and Jersey",
  },
  {
    value: "GU",
    title: "Guam",
  },
  {
    value: "CW",
    title: "Curaçao",
  },
  {
    value: "KI",
    title: "Kiribati",
  },
  {
    value: "VC",
    title: "Saint Vincent and the Grenadines",
  },
  {
    value: "TO",
    title: "Tonga",
  },
  {
    value: "GD",
    title: "Grenada",
  },
  {
    value: "FM",
    title: "Federated States of Micronesia",
  },
  {
    value: "AW",
    title: "Aruba",
  },
  {
    value: "VI",
    title: "United States Virgin Islands",
  },
  {
    value: "AG",
    title: "Antigua and Barbuda",
  },
  {
    value: "SC",
    title: "Seychelles",
  },
  {
    value: "IM",
    title: "Isle of Man",
  },
  {
    value: "AD",
    title: "Andorra",
  },
  {
    value: "DM",
    title: "Dominica",
  },
  {
    value: "KY",
    title: "Cayman Islands",
  },
  {
    value: "BM",
    title: "Bermuda",
  },
  {
    value: "GL",
    title: "Greenland",
  },
  {
    value: "AS",
    title: "American Samoa",
  },
  {
    value: "KN",
    title: "Saint Kitts and Nevis",
  },
  {
    value: "MP",
    title: "Northern Mariana Islands",
  },
  {
    value: "MH",
    title: "Marshall Islands",
  },
  {
    value: "FO",
    title: "Faroe Islands",
  },
  {
    value: "SX",
    title: "Sint Maarten",
  },
  {
    value: "MC",
    title: "Monaco",
  },
  {
    value: "LI",
    title: "Liechtenstein",
  },
  {
    value: "TC",
    title: "Turks and Caicos Islands",
  },
  {
    value: "GI",
    title: "Gibraltar",
  },
  {
    value: "SM",
    title: "San Marino",
  },
  {
    value: "VG",
    title: "British Virgin Islands",
  },
  {
    value: "BQ",
    title: "Caribbean Netherlands",
  },
  {
    value: "PW",
    title: "Palau",
  },
  {
    value: "CK",
    title: "Cook Islands",
  },
  {
    value: "AI",
    title: "Anguilla",
  },
  {
    value: "WF",
    title: "Wallis and Futuna",
  },
  {
    value: "NR",
    title: "Nauru",
  },
  {
    value: "TV",
    title: "Tuvalu",
  },
  {
    value: "PM",
    title: "Saint Pierre and Miquelon",
  },
  {
    value: "MS",
    title: "Montserrat",
  },
  {
    value: "SH",
    title: "Saint Helena Ascension and Tristan da Cunha",
  },
  {
    value: "FK",
    title: "Falkland Islands",
  },
  {
    value: "NU",
    title: "Niue",
  },
  {
    value: "TK",
    title: "Tokelau",
  },
  {
    value: "VA",
    title: "Vatican City",
  },
];
