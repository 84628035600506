import _ from "lodash";

export const getters = {
  token: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  isAuthenticated: (state, getters) => !_.isEmpty(getters.token),
  user: (state) => state.user,
  username: (state, getters) =>
    _.isEmpty(getters.user) ? "" : getters.user.username,
  email: (state, getters) =>
    _.isEmpty(getters.user) ? "" : getters.user.email,
  isSuperAdmin: (state, getters) =>
    _.isEmpty(getters.user) ? false : getters.user.superAdmin,
};
