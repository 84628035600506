<template>
  <v-navigation-drawer
    app
    permanent
    class="elevation-3l"
    :expand-on-hover="$vuetify.breakpoint.smAndDown"
  >
    <v-list>
      <v-list-item class="px-0 mx-0">
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="require('@/assets/logo.svg')" />
          </v-list-item-avatar>
        </v-list-item>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">{{ username }}</v-list-item-title>
          <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list shaped dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <component
          v-for="(linkItem, i) in menuItems"
          :is="linkItem.nested ? 'nav-group' : 'nav-item'"
          :key="i"
          :selected="linkItem.group === groupMap[selectedItem]"
          :item="linkItem"
          color="primary"
        ></component>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $t("logout") }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/actions/identity";

import { SCOPE_FACEBOOK } from "../../../store/modules/service/getters";
import NavItem from "@/views/layouts/parts/NavItem";
import NavGroup from "@/views/layouts/parts/NavGroup";

export default {
  name: "Nav",
  components: { NavGroup, NavItem },
  data: function () {
    return {
      selectedItem: null,
    };
  },
  computed: {
    username: function () {
      return this.$store.getters.username;
    },
    email: function () {
      return this.$store.getters.email;
    },
    isSuperAdmin: function () {
      return this.$store.getters.isSuperAdmin;
    },
    menuItems: function () {
      return [
        {
          route: { name: "Dashboard" },
          title: this.$t("menu.dashboard"),
          icon: "mdi-home",
        },
        {
          route: { name: "ProjectStatistics" },
          title: this.$t("menu.projectStatistics"),
          icon: "mdi-chart-tree",
        },
        {
          title: this.$t("menu.accounts"),
          icon: "mdi-account-multiple",
          nested: [
            {
              route: { name: "AccountList" },
              title: this.$t("menu.accountList"),
              icon: "mdi-account-details",
            },
            {
              route: { name: "AccountHistories" },
              title: this.$t("menu.accountHistories"),
              icon: "mdi-account-clock",
            },
            {
              route: { name: "AccountGeneration" },
              title: this.$t("menu.accountGeneration"),
              icon: "mdi-account-multiple-plus",
            },
          ],
        },
        {
          route: { name: "TagList" },
          title: this.$t("menu.tags"),
          icon: "mdi-tag-multiple-outline",
        },
        {
          title: this.$t("menu.proxyGroup"),
          icon: "mdi-access-point",
          group: "proxy",
          nested: [
            {
              route: { name: "ProxyPackageList" },
              title: this.$t("menu.proxyPackages"),
              icon: "mdi-package-variant",
            },
            {
              route: { name: "ProxyList" },
              title: this.$t("menu.proxies"),
              icon: "mdi-arrow-decision-outline",
            },
          ],
        },
        {
          route: {
            name: "EmulationSettingsList",
            params: { target: SCOPE_FACEBOOK },
          },
          title: this.$t("menu.emulation"),
          icon: "mdi-account-convert",
        },
        {
          title: this.$t("menu.mathGroup"),
          icon: "mdi-robot-confused-outline",
          group: "recommender",
          nested: [
            {
              route: { name: "AudienceRecommender" },
              title: this.$t("menu.audienceRecommender"),
              icon: "mdi-account-network-outline",
            },
            {
              route: { name: "AbuseRecommender" },
              title: this.$t("menu.abuseRecommender"),
              icon: "mdi-alert-octagon-outline",
            },
            {
              route: { name: "ContentRecommender" },
              title: this.$t("menu.contentRecommender"),
              icon: "mdi-post-outline",
            },
            {
              route: { name: "FriendsRecommender" },
              title: this.$t("menu.friendsRecommender"),
              icon: "mdi-handshake-outline",
            },
            {
              route: { name: "VectorInterest" },
              title: this.$t("menu.vectorInterest"),
              icon: "mdi-vector-link",
            },
            {
              route: { name: "Blacklist" },
              title: this.$t("menu.blacklist"),
              icon: "mdi-account-cancel",
            },
          ],
        },
        {
          title: this.$t("menu.systemGroup"),
          icon: "mdi-cog-transfer-outline",
          group: "system",
          nested: [
            {
              route: { name: "ProfileList" },
              title: this.$t("menu.profiles"),
              icon: "mdi-account-group",
            },
            {
              route: { name: "SourceList" },
              title: this.$t("menu.sources"),
              icon: "mdi-semantic-web",
            },
          ],
        },
      ];
    },
    plainMenuMap: function () {
      const getNestedRecursive = function (nestedItems) {
        return nestedItems;
      };

      let items = {};
      let index = 0;
      this.menuItems.forEach(function (item) {
        if (item.nested !== undefined) {
          const nestedItems = getNestedRecursive(item.nested);
          nestedItems.forEach(function (nestedItem) {
            items[nestedItem.route.name] = index;
          });
          index++;
        } else {
          items[item.route.name] = item.route.name;
        }
      });

      return items;
    },
    groupMap: function () {
      let items = [];
      let index = 0;
      this.menuItems.forEach(function (item) {
        if (item.nested !== undefined) {
          items[index++] = item.group;
        }
      });

      return items;
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.selectedItem = this.plainMenuMap[this.$route.name];
      },
      deep: true,
    },
  },
  methods: {
    logout: function () {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.selectedItem = this.plainMenuMap[this.$route.name];
  },
};
</script>
