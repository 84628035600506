import _ from "lodash";
import { excludedFilters } from "@/store/modules/account/histories/state";

export const getters = {
  items: (state) => state.items,
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,
  emptyFilters: (state) => state.emptyFilters,
  formattedFilters: (state) => {
    let filters = {};
    _.forIn(state.filters, function (value, key) {
      if (value !== null && (!(value instanceof Array) || value.length)) {
        filters[key] = value;
      }
    });
    return filters;
  },
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,
  currentPage: (state) => state.pagination.page,
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  scope: (state) =>
    state.filters.scope && state.filters.scope.length > 0
      ? state.filters.scope
      : ["no-scope"],
  search: (state, getters) => {
    let filters = {};
    _.forIn(getters.formattedFilters, function (value, key) {
      if (excludedFilters.includes(key) === false) {
        filters[key] = value;
      }
    });
    return filters;
  },
};
