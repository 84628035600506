import _ from "lodash";

export const getters = {
  params: (state) => state.params,
  items: (state) => state.items,
  pagination: (state) => state.pagination,
  generation: (state) => state.generation,

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalItems,

  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),
  languageAbr: () => {
    return {
      eng: "gb",
      rus: "ru",
      ukr: "ua",
    };
  },
  getAbrByLanguage: (state, getter) => ({ language, defaultValue }) => {
    return _.get(getter.languageAbr, language, defaultValue);
  },
};
