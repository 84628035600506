<template>
  <component :is="layout"></component>
</template>

<script>
import Default from "@/views/layouts/Default";
import Blank from "@/views/layouts/Blank";

export default {
  name: "App",
  components: {
    Default,
    Blank,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  sockets: {
    export: function (message) {
      console.log(message);
      console.log("Got export message");
    },
  },
};
</script>
