import _ from "lodash";

export const getters = {
  //Base getters
  items: (state) => state.items,
  itemsDetails: (state) => state.itemDetails,
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,

  formattedFilters: (state) => _.pickBy(state.filters, _.null),

  //Pagination getters
  itemsPerPage: (state) => state.pagination.rowsPerPage,
  totalCount: (state) => state.pagination.totalCount,
  currentPage: (state) => state.pagination.page,
  //Computed pagination settings
  offset: (state) => state.pagination.rowsPerPage * (state.pagination.page - 1),
  pageCount: (state) =>
    Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage),
  availableActions: () => [
    {
      name: "story",
      color: "#90CAF9",
    },
    {
      name: "post",
      color: "#BBDEFB",
    },
    {
      name: "repost",
      color: "#B3E5FC",
    },
    {
      name: "comment",
      color: "#B2EBF2",
    },
    {
      name: "reply",
      color: "#B2DFDB",
    },
    {
      name: "reaction",
      color: "#A5D6A7",
    },
    {
      name: "abuse",
      color: "#C8E6C9",
    },
    {
      name: "friending",
      color: "#DCEDC8",
    },
    {
      name: "subscribe",
      color: "#F0F4C3",
    },
    {
      name: "follow",
      color: "#FFF9C4",
    },
    {
      name: "joinGroup",
      color: "#FFECB3",
    },
    {
      name: "invite",
      color: "#FFE0B2",
    },
    {
      name: "hidePost",
      color: "#FFCCBC",
    },
    {
      name: "hideComment",
      color: "#FFCDD2",
    },
    {
      name: "hideReply",
      color: "#F8BBD0",
    },
    {
      name: "updateSettings",
      color: "#E1BEE7",
    },
    {
      name: "privateMessage",
      color: "#D1C4E9",
    },
  ],
};
