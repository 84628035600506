export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const EXPORT_ACCOUNTS = "EXPORT_ACCOUNTS";
export const SYNC_ACCOUNTS = "SYNC_ACCOUNTS";
export const SYNC_ACCOUNT = "SYNC_ACCOUNT";
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const PATCH_ACCOUNT = "PATCH_ACCOUNT";
export const FETCH_DONOR_ACCOUNT = "FETCH_DONOR_ACCOUNT";

export const PATCH_ACCOUNT_SETTINGS = "PATCH_ACCOUNT_SETTINGS";
export const FETCH_ACCOUNT_SETTINGS = "FETCH_ACCOUNT_SETTINGS";

export const PATCH_DONOR = "PATCH_DONOR";

export const SET_FILTER = "SET_FILTER";

export const FETCH_TAG_META = "FETCH_TAG_META";
export const FETCH_REGION_META = "FETCH_REGION_META";
export const FETCH_ACCOUNT_TYPES = "FETCH_ACCOUNT_TYPES";
