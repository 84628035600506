//Import store action constants
import {
  FETCH_PACKAGES,
  FETCH_PACKAGE,
  PATCH_PACKAGE,
  FETCH_META,
  FETCH_SOURCE_META,
  SYNC_PACKAGE,
  ACTIVATE_PACKAGE,
} from "@/store/actions/proxy-package";

//Import API endpoint constants
import {
  API_FETCH_PROXY_PACKAGES,
  API_FETCH_PROXY_PACKAGE,
  API_PATCH_PROXY_PACKAGE,
  API_SYNC_PROXY_PACKAGE,
  API_FETCH_PROXY_PACKAGE_META,
  API_FETCH_PROXY_PACKAGE_SOURCE_META,
  API_ACTIVATE_PROXY_PACKAGE,
} from "@/config/api.config";

//Import base api helper
import apiCall from "@/utils/api";

export const actions = {
  //Fetch packages list
  [FETCH_PACKAGES]: ({ commit, getters }) => {
    //Store current page, sorting & filters
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_PROXY_PACKAGES,
        params: {
          limit: getters.itemsPerPage,
          offset: getters.offset,
          sort: getters.formattedSort,
          search: getters.formattedFilters,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Save package list to store via mutation
            commit(FETCH_PACKAGES, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch package details
  [FETCH_PACKAGE]: ({ commit }, { item }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_FETCH_PROXY_PACKAGE,
        parts: {
          id: item.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          if (payload.status && payload.data) {
            //Store package details to the state
            commit(FETCH_PACKAGE, payload.data);
          }
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Update proxy package
  [PATCH_PACKAGE]: (context, { proxyPackage }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_PATCH_PROXY_PACKAGE,
        data: proxyPackage,
        parts: {
          id: proxyPackage.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Update proxy package
  [SYNC_PACKAGE]: (context, { proxyPackage }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_SYNC_PROXY_PACKAGE,
        parts: {
          id: proxyPackage.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Update proxy package
  [ACTIVATE_PACKAGE]: (context, { proxyPackage }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_ACTIVATE_PROXY_PACKAGE,
        parts: {
          id: proxyPackage.id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //Meta data actions
  [FETCH_META]: (context, data) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_PROXY_PACKAGE_META,
        params: data,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_SOURCE_META]: (context, data) => {
    //Return async api handler
    return new Promise((resolve, reject) => {
      apiCall({
        method: API_FETCH_PROXY_PACKAGE_SOURCE_META,
        params: data,
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
