//Import mutation actions constants
import { FETCH_PROXIES, FETCH_PROXY, SET_FILTER } from "@/store/actions/proxy";

export const mutations = {
  //Store list package & pagination params
  [FETCH_PROXIES]: (state, data) => {
    state.items = data.batch;
    state.pagination.totalItems = data.total;
  },
  //Store package details
  [FETCH_PROXY]: (state, data) => {
    state.itemDetails["ID_" + data.id] = data;
  },
  //Store current filters
  [SET_FILTER]: (state, data) => {
    state.filters = data;
    state.pagination.page = 1;
  },
};
