import _ from "lodash";

export const emptyFilters = {
  reportDateFrom: null,
  reportDateTo: null,
  attribute: null,
  oldValue: null,
  newValue: null,
};
const defaultFilters = _.merge({}, emptyFilters, {});

export const state = {
  items: [], //List of donors with base info only
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
  },
  filters: defaultFilters,
};
