import _ from "lodash";

export const emptyFilters = {
  id: null,
  username: null,
  email: null,
  title: null,
  status: null,
  superAdmin: null,
  createdAt: null,
  updatedAt: null,
};

const defaultFilters = _.merge({}, emptyFilters, {
  status: "active",
});

export const state = {
  items: [], //List of users with base info only
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
  },
  sort: {
    //Ordering state, attribute & direction
    sortBy: "id",
    desc: true,
  },
  filters: defaultFilters,
  emptyFilters,
};
