import _ from "lodash";

export const emptyFilters = {
  hash: null,
  type: null,
  stage: null,
};

export const multipleFilters = ["type", "stage"];

export const defaultFilters = _.merge({}, emptyFilters);

export const state = {
  items: [], //List of services with base info only
  pagination: {
    //Pagination state, total count & settings
    page: 1,
    rowsPerPage: 10,
    totalItems: 0,
  },
  sort: {
    //Ordering state, attribute & direction
    sortBy: "base",
    desc: false,
  },
  filters: _.merge({}, defaultFilters),
  emptyFilters,
  defaultFilters,
};
